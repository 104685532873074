export const uuid = () => {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  let date1 = new Date().getTime();
  let date2 = 0;

  const replacer = (char) => {
    let random = Math.random() * 16;

    if (date1 > 0) {
      random = (date1 + random) % 16 | 0;
      date1 = Math.floor(date1 / 16);
    } else {
      random = (date2 + random) % 16 | 0;
      date2 = Math.floor(date2 / 16);
    }
    return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  };

  return template.replace(/[xy]/g, replacer);
};
