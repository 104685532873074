import './UsersList.css';
import React, { useEffect } from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { EditUserPopup } from '../EditUserPopup/EditUserPopup';
import { EditUserLimitPopup } from '../EditUserPopup/EditUserLimitPopup';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { useParams } from 'react-router-dom';
import {
  useGetProjectByIdQuery,
  useNewLimitProjectTeamMembersMutation,
} from 'src/services';
import { clsx } from 'clsx';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';

export const UsersList = ({ users = [] }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');
  const notification = useNotification();
  const loader = useLoader();

  const { data: userInfo } = useGetUsersMeQuery();

  const isEmpty = !Boolean(users && users.length);
  return isEmpty ? (
    <div className="empty-message">You are now the only user.</div>
  ) : (
    <section className="team-list">
      {isMdDown ? (
        <div className="gap-[6px] flex flex-col mt-[10px]">
          {users &&
            users.map((user) => {
              const isActive = user.is_active;
              return (
                <div
                  key={user.id}
                  className="flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border"
                >
                  <div className="text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
                    <div className="col name md:text-base text-sm flex justify-between">
                      <div className="flex items-center gap-[8px]">
                        <UserAvatar user={user} />
                        <span
                          className="truncate"
                          title={`${user.first_name} ${user.last_name}`}
                        >{`${user.first_name} ${user.last_name}`}</span>
                        <StatusBadge isActive={isActive} />
                      </div>
                      {/*<EditUserPopup teamMember={user} />*/}
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Email:
                    </span>
                    <p title={user.email} className="truncate">
                      {user.email}
                    </p>
                  </div>
                  <div
                    className={'flex items-center text-xs p-[15px] gap-[10px]'}
                  >
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Role:
                    </span>
                    <p>{user.role}</p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table wrapperClassName="pt-14 -mt-14">
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                <TableHead>Role:</TableHead>
                <TableHead>Email address:</TableHead>
                <TableHead>Status:</TableHead>
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users &&
                users.map((user) => {
                  const isActive = user.is_active;

                  return (
                    <TableRow key={user.id}>
                      <TableCell className="font-medium table-fixed max-w-80">
                        <div className="col name md:text-base text-base">
                          <UserAvatar user={user} />
                          <span
                            title={`${user.first_name} ${user.last_name}`}
                          >{`${user.first_name} ${user.last_name}`}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col role flex-5 text-base">
                          {user.role}
                        </div>
                      </TableCell>
                      <TableCell title={user.email} className="max-w-80">
                        <div
                          className="col col-email text-base"
                          title={user.email}
                        >
                          {user.email}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col status flex-5">
                          <StatusBadge isActive={isActive} />
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          {/*<EditUserPopup teamMember={user} />*/}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
