import { baseApi } from './base.api';
import { PATH_BILLING_API } from '../constants/api-routes';

export const billingService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethods: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
      }),
      providesTags: ['PaymentMethods'],
    }),
    getPayments: build.query({
      query: ({ starting_after = '', start_date = '', end_date = '' }) => ({
        url: `${PATH_BILLING_API.PAYMENTS}`,
        params: {
          ...(starting_after !== '' && { starting_after }),
          ...(start_date !== '' && { start_date }),
          ...(end_date !== '' && { end_date }),
        },
      }),
      providesTags: ['Payments'],
    }),
    getAllPlans: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.PLANS_ALL}`,
      }),
    }),
    addPaymentMethods: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
        method: 'POST',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods', id }];
      },
    }),
    deletePaymentMethods: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
        method: 'DELETE',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods', id }];
      },
    }),
    setDefaultPaymentMethod: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHOD_DEFAULT}`,
        method: 'POST',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods' }];
      },
    }),
    getProjectsSubscriptions: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.PROJECTS_SUBSCRIPTION}`,
      }),
      providesTags: ['ProjectPlans'],
    }),
    getInvoicePdf: build.query({
      query: ({ payment_id }) => ({
        url: `${PATH_BILLING_API.INVOICE_PDF}`,
        params: {
          payment_id,
        },
      }),
    }),
    createProjectPlans: build.mutation({
      query: ({ project_id, plan_id, target_service }) => ({
        url: `${PATH_BILLING_API.CREATE_PROJECT_PLANS}`,
        method: 'POST',
        body: {
          project_id: project_id,
          plan_id: plan_id,
          target_service: target_service,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'ProjectPlans', id }];
      },
    }),
    activateSubscription: build.mutation({
      query: ({ subscription_items }) => ({
        url: `${PATH_BILLING_API.CREATE_SUBSCRIPTION}`,
        method: 'POST',
        body: {
          method: 'stripe',
          subscription_items: subscription_items,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['ProjectPlans', 'Accounts'];
      },
    }),
    deleteSubscription: build.mutation({
      query: ({ project_plan_id, target_service }) => ({
        url: `${PATH_BILLING_API.DELETE_SUBSCRIPTION}`,
        method: 'POST',
        body: {
          project_plan_id: project_plan_id,
          target_service: target_service,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'ProjectPlans', id }];
      },
    }),
    changeSubscription: build.mutation({
      query: ({
        changeable_project_plan_id,
        new_plan_id,
        project_id,
        target_service,
      }) => ({
        url: `${PATH_BILLING_API.CHANGE_SUBSCRIPTION}`,
        method: 'POST',
        body: {
          new_plan_id,
          project_id,
          target_service,
          changeable_project_plan_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'ProjectById', id }];
      },
    }),
    cancelSubscription: build.mutation({
      query: () => ({
        url: `${PATH_BILLING_API.CANCEL_SUBSCRIPTION}`,
        method: 'POST',
      }),
      invalidatesTags: ['ProjectPlans', 'Accounts'],
    }),
    subscriptionAddItem: build.mutation({
      query: ({ project_plan_id, target_service }) => ({
        url: `${PATH_BILLING_API.SUBSCRIPTION_ADD_ITEM}`,
        method: 'POST',
        body: {
          target_service: target_service,
          project_plan_id: project_plan_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'ProjectPlans', id }];
      },
    }),
    extendProjectPlan: build.mutation({
      query: ({ project_id, target_service }) => ({
        url: `${PATH_BILLING_API.EXTEND_PROJECT_PLAN}`,
        method: 'POST',
        body: {
          project_id: project_id,
          target_service: target_service,
        },
      }),
      async onQueryStarted({ project_id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            billingService.util.updateQueryData(
              'getProjectsSubscriptions',
              {},
              (draft) => {
                draft.find(
                  (project) => project.id === project_id,
                ).is_extended_manually = true;
              },
            ),
          );
        } catch (err) {
          console.error('Error updating project team members:', err);
        }
      },
    }),
    deExtendProjectPlan: build.mutation({
      query: ({ project_id, target_service }) => ({
        url: `${PATH_BILLING_API.DE_EXTEND_PROJECT_PLAN}`,
        method: 'POST',
        body: {
          project_id: project_id,
          target_service: target_service,
        },
      }),
      async onQueryStarted({ project_id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            billingService.util.updateQueryData(
              'getProjectsSubscriptions',
              {},
              (draft) => {
                draft.find(
                  (project) => project.id === project_id,
                ).is_extended_manually = false;
              },
            ),
          );
        } catch (err) {
          console.error('Error updating project team members:', err);
        }
      },
    }),
    getSubscriptionPermission: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.SUBSCRIPTION_PERMISSION_GET}`,
      }),
      providesTags: ['SubscriptionPermission'],
    }),
    setSubscriptionPermission: build.mutation({
      query: ({ approved }) => ({
        url: `${PATH_BILLING_API.SUBSCRIPTION_PERMISSION_SET}`,
        method: 'POST',
        body: {
          approved,
        },
      }),
      async onQueryStarted({ approved }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            billingService.util.updateQueryData(
              'getSubscriptionPermission',
              undefined,
              (draft) => {
                draft.approved = approved;
              },
            ),
          );
        } catch (err) {
          console.error('Error set payment methods:', err);
        }
      },
    }),
  }),
});

export const {
  useGetAllPlansQuery,
  useGetPaymentsQuery,
  useCreateProjectPlansMutation,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodsMutation,
  useSetDefaultPaymentMethodMutation,
  useGetProjectsSubscriptionsQuery,
  useActivateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useCancelSubscriptionMutation,
  useSubscriptionAddItemMutation,
  useGetInvoicePdfQuery,
  useDeletePaymentMethodsMutation,
  useExtendProjectPlanMutation,
  useDeExtendProjectPlanMutation,
  useGetSubscriptionPermissionQuery,
  useSetSubscriptionPermissionMutation,
  useChangeSubscriptionMutation,
} = billingService;
