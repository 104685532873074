import React from 'react';

export const ExpandCollapseIcon = () => (
  <svg
    className={`expand-collapse-icon`}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <g clipPath="url(#clip0_3885_14865)">
      <path
        d="M0.520805 2.99995L6.88477 9.36391L13.2487 2.99995"
        stroke="#05050D"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_3885_14865">
        <rect width="14" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
