import { baseApi } from './base.api';
import { STATISTICS_URL } from '../shared/constants';
import constants from 'src/pages/AnalyticsPage/constants';
import moment from 'moment';

const sortByDate = (parsedResponse) => {
  if (parsedResponse.isByUser) {
    for (let key in parsedResponse) {
      if (Array.isArray(parsedResponse[key])) {
        parsedResponse[key].sort((item, item2) => {
          return (
            moment(item.date).format('YYYYMMDD') -
            moment(item2.date).format('YYYYMMDD')
          );
        });
      }
    }
  } else {
    parsedResponse.data.sort((item, item2) => {
      return (
        moment(item.date).format('YYYYMMDD') -
        moment(item2.date).format('YYYYMMDD')
      );
    });
  }
};

const formatDateRange = (date) => {
  return date
    .split(' ')
    .map((date) => moment(date).format('ll'))
    .join(' - ');
};

const parseByMetric = (metric, dataKey, response, result) => {
  const data = response.data[dataKey];
  result = result || {
    isByUser: false,
    data: [],
  };
  result.isByUser = !metric.startsWith('subdomain_');

  if (result.isByUser) {
    Object.keys(data).forEach((key) => {
      Object.keys(data[key]).forEach((userKey) => {
        result[key] = result[key] || [];
        const date = moment(userKey).format('ll');
        const dataItem = result[key].find((item) => {
          return item.date === date;
        });

        if (dataItem) {
          dataItem[constants[metric]] = data[key][userKey];
        } else {
          result[key].push({
            date,
            [constants[metric]]: data[key][userKey],
          });
        }
      });
    });
  } else {
    Object.keys(data.subdomain).forEach((key) => {
      const time = moment(key);
      const date = time.format('ll');
      const dataItem = result.data.find((item) => {
        return item.date === date;
      });

      if (dataItem) {
        dataItem[constants[metric]] = data.subdomain[key];
      } else {
        result.data.push({ date, [constants[metric]]: data.subdomain[key] });
      }
    });
  }
};

const parseResponse = (request, response) => {
  const result = {
    isByUser: false,
    data: [],
  };

  request.metrics.forEach((metric) => {
    let dataKey =
      request.type_of_statistic === 'normalized_metrics'
        ? `${metric}_nm`
        : metric;

    dataKey = dataKey.replace('subdomain_', '');

    if (!response.data[dataKey]) {
      return;
    }

    parseByMetric(metric, dataKey, response, result);
  });

  return result;
};

const formatAverageDate = (value, timeAxis) => {
  const duration = moment.duration(value, timeAxis);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const utc = moment.utc(duration.asMilliseconds());

  console.log('value', value);
  console.log('duration', duration);
  console.log('days', days);
  console.log('hours', hours);
  console.log('minutes', minutes);
  console.log('utc', utc);

  if (days) {
    return utc.format(`[${days}d] HH:mm:ss`);
  }

  return utc.format('HH:mm:ss');
};

const calculateAverage = (request, parsedResponse) => {
  const result = {};

  request.metrics.forEach((metric) => {
    if (parsedResponse.isByUser) {
      for (let key in parsedResponse) {
        const isArray = Array.isArray(parsedResponse[key]);

        isArray &&
          parsedResponse[key].forEach((dataItem) => {
            request.metrics.forEach((metric) => {
              const metricName = constants[metric];

              result[metricName] = result[metricName] || [];
              dataItem[metricName] &&
                result[metricName].push(dataItem[metricName]);
            });
          });
      }
    } else {
      parsedResponse.data.forEach((dataItem) => {
        request.metrics.forEach((metric) => {
          const metricName = constants[metric];

          result[metricName] = result[metricName] || [];

          dataItem[metricName] && result[metricName].push(dataItem[metricName]);
        });
      });
    }
  });

  for (let key in result) {
    const sum = result[key].reduce((acc, curr) => acc + curr, 0);

    result[key] = sum / result[key].length;
    result[key] = formatAverageDate(result[key], request['time_axis']);
  }

  return result;
};

export const analyticsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAnalytics: build.mutation({
      query: (params) => ({
        url: `${STATISTICS_URL}`,
        method: 'POST',
        body: params,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        let parsedResponse = parseResponse(arg, baseQueryReturnValue);
        const average = calculateAverage(arg, parsedResponse);

        if (!parsedResponse) {
          return baseQueryReturnValue;
        }

        sortByDate(parsedResponse);

        const { isByUser, data, ...rest } = parsedResponse;

        return {
          ...baseQueryReturnValue,
          average,
          parsedResponse: parsedResponse.isByUser ? rest : parsedResponse.data,
          isByUser: parsedResponse.isByUser,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: fetchedAnalytics } = await queryFulfilled;
          dispatch(
            analyticsService.util.updateQueryData(
              'fetchAnalytics',
              undefined,
              (draft) => {
                draft?.push(fetchedAnalytics);
              },
            ),
          );
        } catch {}
      },
    }),
  }),
});

export const { useFetchAnalyticsMutation } = analyticsService;
