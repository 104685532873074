import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { FolderIcon } from 'src/assets/icons/FolderIcon';
import { Checkbox } from 'src/components/Checkbox/Checkbox';
import { FileSettingsPopover } from 'src/components/FileManager/FileSettingsPopover/FileSettingsPopover';
import { useSingleAndDoubleClick } from 'src/hooks/useSingleAndDoubleClick';

export const ListFolderView = ({
  item,
  onClick,
  onDoubleClick,
  className,
  checkboxVisible,
  handleSingleItemMove,
  handleSingleItemRename,
  handleSingleItemDelete,
  ...props
}) => {
  const [checked, setChecked] = useState(Boolean(item.checked));
  const [isSettingsVisible, setIsSettingsVisible] = useState(
    item.name !== '..' && Boolean(item.children.length),
  );
  useEffect(() => {
    if (!checkboxVisible) {
      setChecked(false);
      item.checked = false;
    }
  }, [checkboxVisible]);

  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick);

  const handleChange = (event, child) => {
    const file = child || item;

    file.checked = event.target.checked;

    if (file.children) {
      for (let i = 0; i < file.children.length; i++) {
        const child = file.children[i];

        child.checked = event.target.checked;

        if (child.children && child.children.length) {
          handleChange(event, child);
        }
      }
    }
  };

  return (
    <>
      <div
        className={clsx(
          className,
          'file-list-item folder',
          item.checked && 'checked',
        )}
        onClick={handleClick}
        {...props}
      >
        <div className={'file-list-name'}>
          <div
            className={clsx(
              item.name === '..'
                ? 'invisible pointer-events-none'
                : checkboxVisible
                  ? 'pointer-events-auto'
                  : 'invisible pointer-events-none',
              'file-list-checkbox',
            )}
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox
              label={''}
              checked={checked}
              onChange={(event) => {
                setChecked(event.target.checked);
                handleChange(event, item);
              }}
            />
          </div>
          <div className={'file-list-icon'}>
            <FolderIcon />
          </div>
          <div className={'file-list-text text-wrap'}>
            <p
              title={item.name}
              className="w-[inherit] line-clamp-3 hyphens-auto break-words select-none"
            >
              {item.name}
            </p>
          </div>
          <div className={'file-list-settings folder'}>
            {isSettingsVisible && (
              <div className={'file-list-settings-icon pointer-events-auto'}>
                {!item.disabled && (
                  <FileSettingsPopover
                    item={item}
                    moveItem={(moveToFolder) =>
                      handleSingleItemMove(moveToFolder, item)
                    }
                    renameItem={(name) => handleSingleItemRename(name, item)}
                    deleteItem={() => handleSingleItemDelete(item)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
