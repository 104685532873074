import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/services';
import { AddIntegrationPopup } from 'src/components/AddIntegrationPopup/AddIntegrationPopup';
import { IntegrationsList } from 'src/components/IntegrationsList/IntegrationsList';
import { getErrorMessage } from 'src/utils/get-error-message';
import { ProductsType } from 'src/constants/products';
import {
  IntegrationTypeAgent,
  IntegrationTypeBi,
  IntegrationTypeCustomer,
} from 'src/components/AddIntegrationPopup/IntegrationTypes';
import { useAppSelector } from 'src/store';

const IntegrationsPage = () => {
  const notification = useNotification();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { id } = useParams();
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const {
    data: currentProject,
    isFetching: isFetchingProject,
    isError,
    error,
  } = useGetProjectByIdQuery({ id: id });
  const integrationTypes =
    (projectType === ProductsType.agent && IntegrationTypeAgent) ||
    (projectType === ProductsType.customer && IntegrationTypeCustomer) ||
    (projectType === ProductsType.bi && IntegrationTypeBi);

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  const isAddIntegrationAvailable =
    (integrationTypes &&
      integrationTypes.filter(
        (type) =>
          !currentProject?.integrations.some(
            (integration) =>
              integration.integration_type === type.integration_type,
          ),
      ).length) ||
    null;

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx(
          'knowledge-base-page-container',
          !isLgDown && 'relative',
        )}
        isMobileMarginTop={false}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="integration">
            <div className="card-header">
              <div className="title">
                <span>Integrations</span>
              </div>
              <div className="add-button-container">
                {currentProject && isAddIntegrationAvailable && (
                  <AddIntegrationPopup project={currentProject} />
                )}
              </div>
            </div>
            <div className="card-body">
              <IntegrationsList
                isFetchingProject={isFetchingProject}
                isEmpty={!currentProject?.integrations?.length}
                integrations={currentProject?.integrations}
              />
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default IntegrationsPage;
