import React, { useEffect } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { AddPaymentMethodPopup } from './AddPaymentMethodPopup/AddPaymentMethodPopup';
import {
  useDeletePaymentMethodsMutation,
  useGetPaymentMethodsQuery,
  useSetDefaultPaymentMethodMutation,
} from 'src/services';
import { Table, TableBody, TableCell, TableRow } from 'src/components/ui/table';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { clsx } from 'clsx';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import { useAppSelector } from 'src/store';

export const PaymentMethodCard = () => {
  const notification = useNotification();
  const loader = useLoader();

  const { companyChanged } = useAppSelector((s) => s.companySlices);

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    isError: paymentMethodsIsError,
    isFetching: paymentMethodsIsFetching,
  } = useGetPaymentMethodsQuery(
    {},
    { refetchOnMountOrArgChange: companyChanged },
  );

  const [
    setDefaultPaymentMethod,
    {
      isLoading: isLoadingSetDefaultPaymentMethod,
      isError: isSetDefaultPaymentMethodError,
      error: setDefaultPaymentMethodError,
      isSuccess: isSetDefaultPaymentMethodSuccess,
    },
  ] = useSetDefaultPaymentMethodMutation();

  const [
    deletePaymentMethod,
    {
      isLoading: deletePaymentMethodIsLoading,
      isError: isDeletePaymentMethodError,
      error: deletePaymentMethodError,
      isSuccess: deletePaymentMethodIsSuccess,
    },
  ] = useDeletePaymentMethodsMutation();

  useEffect(() => {
    if (isLoadingSetDefaultPaymentMethod) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingSetDefaultPaymentMethod]);

  useEffect(() => {
    if (isSetDefaultPaymentMethodError) {
      notification.error(getErrorMessage(setDefaultPaymentMethodError));
    }
    if (paymentMethodsIsError) {
      notification.error(getErrorMessage(paymentMethodsError));
    }
    if (isDeletePaymentMethodError) {
      notification.error(getErrorMessage(deletePaymentMethodError));
    }
  }, [
    isSetDefaultPaymentMethodError,
    paymentMethodsIsError,
    isDeletePaymentMethodError,
  ]);

  useEffect(() => {
    if (isSetDefaultPaymentMethodSuccess) {
      notification.success('Payment method was changed successfully.');
    }
    if (deletePaymentMethodIsSuccess) {
      notification.success('Payment method was deleted successfully.');
    }
  }, [isSetDefaultPaymentMethodSuccess, deletePaymentMethodIsSuccess]);

  const handlePaymentMethodClick = (id) => {
    setDefaultPaymentMethod({ payment_method_id: id });
  };

  const handlePaymentMethodDelete = (id) => {
    deletePaymentMethod({ payment_method_id: id });
  };

  return (
    <CardContainer className={`half-width`}>
      <div className="card-header">
        <div className="title">Payment method</div>
        <div className="edit-button-container">
          <AddPaymentMethodPopup />
        </div>
      </div>
      <div className="card-body">
        <div className={'full-width'}>
          <Table>
            <TableBody
              className={clsx(
                (paymentMethodsIsFetching || deletePaymentMethodIsLoading) &&
                  paymentMethods?.data?.length &&
                  'animate-pulse pointer-events-none blur-sm',
              )}
            >
              {Boolean(paymentMethods?.data?.length) ? (
                paymentMethods?.data.map((paymentItem) => {
                  const isDefault = paymentItem.default;
                  return (
                    <TableRow
                      onClick={() =>
                        !isDefault && handlePaymentMethodClick(paymentItem.id)
                      }
                      key={paymentItem.id}
                      className={clsx(
                        'transition',
                        !isDefault && 'hover:bg-light cursor-pointer',
                      )}
                    >
                      <TableCell className="font-medium table-fixed w-4">
                        <div className="col">
                          <div className="w-3 h-3 rounded-full relative border border-ebony">
                            <div
                              className={clsx(
                                'absolute w-[7.5px] h-[7.5px] transition rounded-full bg-ebony top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                                isDefault ? 'block' : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="col role flex-5 text-base">
                          <p className="font-medium text-base text-ebony opacity-70">
                            <span className="capitalize">
                              {paymentItem.brand}
                            </span>{' '}
                            card
                            <span> ****{paymentItem.last4}</span>
                          </p>
                        </div>
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <div>
                          <DeletePopup
                            title="Delete payment method?"
                            description={
                              <span className="font-medium text-base text-ebony opacity-70">
                                Are you sure you want to delete{' '}
                                <span className="capitalize">
                                  {paymentItem.brand}
                                </span>{' '}
                                card
                                <span> ****{paymentItem.last4}</span>
                              </span>
                            }
                            deleteHandler={() =>
                              handlePaymentMethodDelete(paymentItem.id)
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="font-medium table-fixed max-w-80">
                    <div className="col">
                      <LoaderWrapper
                        loader={
                          <div className="flex gap-10">
                            <div className="flex items-center w-full gap-5">
                              <Skeleton className="h-3 w-3" />
                              <Skeleton className="h-6 flex-1" />
                            </div>
                            <Skeleton className="w-6 h-6" />
                          </div>
                        }
                        isLoading={paymentMethodsIsFetching}
                      >
                        <p className="font-medium text-base text-ebony opacity-70">
                          Add payment method to your account.
                        </p>
                      </LoaderWrapper>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </CardContainer>
  );
};
