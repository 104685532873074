import '../ProjectPage.css';
import { useParams } from 'react-router-dom';
import AIReplyGenerationIcon from '../../../assets/img/ai-reply-generation.svg';
import TextLenseIcon from '../../../assets/img/text-lense-ai.svg';
import React, { useEffect, useState } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { TeamList } from 'src/components/TeamList/TeamList';
import { AddTeamMemberPopup } from 'src/components/AddTeamMemberPopup/AddTeamMemberPopup';
import { useLoader } from 'src/provider/LoaderProvider';
import AnimateHeight from 'react-animate-height';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../../components/CardContainer/CardContainer';
import ActiveProductsCard from '../../../components/ActiveProductsCard/ActiveProductsCard';
import { setProjectId } from 'src/store/slices/projectsSlices';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useGetProjectByIdQuery, useUpdateProjectMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { PaginationComponent } from 'src/components/Pagination/PaginationComponent';
import { usePagination } from 'src/hooks/usePagination';
import { ExpandIcon } from 'src/assets/icons/expandIcon';
import { SubscriptionOverviewList } from 'src/components/SubscriptionOverviewList/SubscriptionOverviewList';
import { ProductsType as ProjectsType } from 'src/constants/products';
import { EditSubscriptionPopup } from 'src/components/EditSubscriptionPopup/EditSubscriptionPopup';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { ROLES } from 'src/constants/roles';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';

const Counter = ({ items }) =>
  items && <div className={'yellow-counter'}>{items.length}</div>;

export const CurrentProjectScreen = () => {
  const { id } = useParams();
  const [aiReplyGeneration, setAiReplyGeneration] = useState(false);
  const [textLenseAI, setTextLenseAI] = useState(false);
  const [projectTeamMembers, setProjectTeamMembers] = useState([]);
  const loader = useLoader();
  const notification = useNotification();
  const [teamHeight, setTeamHeight] = useState('auto');
  const isMdDown = useMediaQuery('(max-width: 767px)');
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const dispatch = useAppDispatch();
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const [
    updateProject,
    {
      isLoading,
      isError: isUpdateProjectError,
      error: updateProjectError,
      isSuccess,
    },
  ] = useUpdateProjectMutation();

  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } =
    usePagination();

  const { data: userInfo } = useGetUsersMeQuery();

  const {
    data: currentProject,
    isFetching: isFetchingProject,
    isError,
    error,
  } = useGetProjectByIdQuery({ id: id });

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isFetchingProject) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isFetchingProject, projectType]);

  useEffect(() => {
    if (id && currentProject?.project_type) {
      localStorage.setItem(LATEST_VIEWED_PROJECT_ID, id);
      dispatch(
        setProjectId({
          projectId: id,
          projectType: currentProject?.project_type,
        }),
      );
    }
  }, [id, currentProject]);

  useEffect(() => {
    if (currentProject) {
      setAiReplyGeneration(currentProject.ai_reply_generation);
      setTextLenseAI(currentProject.ai_text_lense);
    }
  }, [currentProject]);

  const toggleAiReplyGeneration = async (checked) => {
    var data = {
      name: currentProject.name,
      ai_text_lense: currentProject.ai_text_lense,
      ai_reply_generation: checked,
    };
    const response = await updateProject(id, data);

    if (response instanceof Error) {
      response.message && notification.error(response.message);
      setAiReplyGeneration(!checked);
    }

    setAiReplyGeneration(response.ai_reply_generation);
    notification.success('Updated Successfully');
  };
  const toggleTextLenseAI = async (checked) => {
    var data = {
      name: currentProject.name,
      ai_text_lense: checked,
      ai_reply_generation: currentProject.ai_reply_generation,
    };
    const response = await updateProject(id, data);

    if (response instanceof Error) {
      response.message && notification.error(response.message);
      setTextLenseAI(!checked);
    }

    setTextLenseAI(response.ai_text_lense);
    notification.success('Updated Successfully');
  };

  useEffect(() => {
    if (currentProject) {
      const teamMembers = currentProject?.team_members?.reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(index / pagination.rowsPerPage);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        },
        [],
      );
      setProjectTeamMembers(teamMembers);
    }
  }, [currentProject, pagination]);

  const isAgentType = projectType === ProjectsType.agent;

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return (
    <React.Fragment>
      <PageContentWrapper
        className="project-page-container"
        isMobileMarginTop={false}
      >
        {isAgentType && (
          <>
            <div className={'project-main-container'}>
              {!isMdDown ? (
                <>
                  <CardContainer>
                    <ActiveProductsCard
                      icon={AIReplyGenerationIcon}
                      title={'AI Reply Generation'}
                      subTitle={'Generate complete text replies'}
                      checked={true}
                    />
                    {/*<ToggleButton*/}
                    {/*    checked={aiReplyGeneration}*/}
                    {/*    onChange={toggleAiReplyGeneration}*/}
                    {/*/>*/}
                  </CardContainer>
                  <CardContainer>
                    <ActiveProductsCard
                      icon={TextLenseIcon}
                      title={'Text Lense AI'}
                      subTitle={'Contextual text formating'}
                      checked={false}
                    />
                    {/*<ToggleButton*/}
                    {/*    checked={textLenseAI}*/}
                    {/*    onChange={toggleTextLenseAI}*/}
                    {/*/>*/}
                  </CardContainer>
                </>
              ) : (
                <CardContainer className="flex flex-col gap-[6px]">
                  <div className="card-header">
                    <div className="title">
                      <span>Active Products</span>
                    </div>
                  </div>
                  <ActiveProductsCard
                    icon={AIReplyGenerationIcon}
                    title={'AI Reply Generation'}
                    checked={true}
                    isMobile
                  />
                  <ActiveProductsCard
                    icon={TextLenseIcon}
                    title={'Text Lense AI'}
                    checked={false}
                    isMobile
                  />
                </CardContainer>
              )}
            </div>
          </>
        )}
        <CardContainer>
          <div className="card-header z-50">
            <div className="title no-underline">
              <span>Subscription</span>
            </div>
            <div className="add-button-container relative">
              {currentProject?.project_plan && (
                <>
                  <EditSubscriptionPopup
                    project={currentProject}
                    disabled={!isCosupportManager}
                  />
                  {!isCosupportManager && (
                    <WarningPopover
                      triggerClassName="absolute -right-2 -top-2"
                      fill="black"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="card-body flex flex-col gap-3">
            <div className="w-full flex justify-end">
              <SubscriptionOverviewList
                isFetchingProject={isFetchingProject}
                isEmpty={Boolean(currentProject?.project_plan)}
                currentProject={currentProject}
              />
            </div>
          </div>
        </CardContainer>
        {isAgentType && (
          <CardContainer className="team">
            <div className="card-header z-50">
              <div
                className={teamHeight ? 'title' : 'title collapsed'}
                onClick={() => setTeamHeight(teamHeight ? 0 : 'auto')}
              >
                <Counter
                  items={currentProject && currentProject.team_members}
                />{' '}
                <span>Team</span> <ExpandIcon />
              </div>
              <div className="add-button-container">
                {currentProject && (
                  <AddTeamMemberPopup project={currentProject} />
                )}
              </div>
            </div>
            <AnimateHeight height={teamHeight}>
              <div className="card-body flex flex-col gap-3">
                {currentProject && projectTeamMembers && (
                  <TeamList team={projectTeamMembers[pagination.page - 1]} />
                )}
                <div className="w-full flex justify-end">
                  {!!currentProject?.team_members?.length && (
                    <PaginationComponent
                      rowsPerPageOptions={[5, 10, 15]}
                      count={currentProject?.team_members?.length}
                      rowsPerPage={pagination.rowsPerPage}
                      currentPage={pagination.page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                    />
                  )}
                </div>
              </div>
            </AnimateHeight>
          </CardContainer>
        )}
      </PageContentWrapper>
    </React.Fragment>
  );
};
