import React, { useEffect } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { PaymentDetailsPopup } from './PaymentDetailsPopup/PaymentDetailsPopup';
import { useGetProjectsSubscriptionsQuery } from 'src/services';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { ProjectPlanStatus } from 'src/constants/projects';

const DEFAULT_VALUE = {
  projects: [],
  cost: null,
};

export const PaymentDetailsCard = () => {
  const [subscriptionsData, setSubscriptionsData] =
    React.useState(DEFAULT_VALUE);

  const { data: accounts, isFetching: accountIsFetching } =
    useGetAccountsQuery();

  const {
    data: projectsSubscriptions = [],
    isFetching: projectsSubscriptionsIsFetching,
  } = useGetProjectsSubscriptionsQuery({});

  const projectsReducedValueHandler = (statusFilter = ['']) => {
    const projSubscriptions = projectsSubscriptions?.filter(
      (project) => !!project?.project_plan,
    );

    const draftAndActiveProj = projSubscriptions.filter((project) =>
      statusFilter.includes(project?.project_plan.status),
    );
    const draftAndActiveProjReduce = draftAndActiveProj.reduce((acc, curr) => {
      return (
        acc +
        (curr.project_plan.plan?.price +
          curr?.credit_count * curr?.project_plan.plan?.extra_request_price ||
          0)
      );
    }, 0);

    return {
      projects: draftAndActiveProj,
      cost: draftAndActiveProjReduce,
    };
  };

  useEffect(() => {
    if (projectsSubscriptions?.length) {
      setSubscriptionsData(
        projectsReducedValueHandler([ProjectPlanStatus.ACTIVE]),
      );
    } else {
      setSubscriptionsData(DEFAULT_VALUE);
    }
  }, [projectsSubscriptions, accounts]);

  return (
    <CardContainer className={`half-width flex-col justify-between`}>
      <div className="card-header">
        <div className="title">Next Payment</div>
        <div className="edit-button-container">
          <PaymentDetailsPopup
            subscriptionsData={subscriptionsData}
            disabled={
              !subscriptionsData.projects.length ||
              projectsSubscriptionsIsFetching ||
              accountIsFetching
            }
          />
        </div>
      </div>
      <div className="card-body  h-full py-1">
        <div
          className={
            'input-container full-width border rounded-[7px] h-full px-4 py-3.5 flex justify-between items-end'
          }
        >
          <LoaderWrapper
            loader={
              <div className="flex justify-between w-full items-end">
                <Skeleton className="h-7 w-14" />
                <Skeleton className="h-3.5 w-20" />
              </div>
            }
            isLoading={projectsSubscriptionsIsFetching || accountIsFetching}
          >
            <p className="md:text-3xl text-xl font-bold text-ebony">
              {subscriptionsData?.cost?.toFixed(2) || 0} $
            </p>
            {accounts?.stripe_subscription_end && (
              <p className="text-xs font-medium text-ebony md:opacity-50 opacity-70">
                On{' '}
                {transformDateHelper(
                  accounts?.stripe_subscription_end,
                  DateFormat.MONTH_DAY_YEAR_WITHOUT_COMA,
                )}
              </p>
            )}
          </LoaderWrapper>
        </div>
      </div>
    </CardContainer>
  );
};
