import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../utils/rtk-headers';
import { environment } from '../env/env';
import { refreshToken } from 'src/services/FetchRequest';
import { PATH_AUTH_SPA } from 'src/constants/spa-routes';
import {
  refetchQuerysWithAccountParam,
  refetchQuerysWithProjectTypeParam,
} from 'src/constants/refetch-account-params';
import { ProductsTypeBack } from 'src/constants/products';

export const baseUrl = environment.BASE_URL;

let user = localStorage.getItem('user');
user = user && user !== 'undefined' && JSON.parse(user);
const refresh = user && user.refresh;
let isRefreshing = false;

const staggeredBaseQueryWithBailOut = retry(
  async (args, api, extraOptions) => {
    const state = api.getState();
    const {
      companySlices: { managedAccountId },
      projectsSlices: { projectType },
    } = state;

    const searchParams = new URLSearchParams(location.search);
    const searchProjectType = searchParams.get('type');

    const projectTypeVar = projectType || searchProjectType;

    const urlPath = `/api/${args.url}`;

    const url = new URL(urlPath, baseUrl);

    if (
      managedAccountId &&
      refetchQuerysWithAccountParam.includes(api.endpoint)
    ) {
      url.searchParams.set('account', managedAccountId);
    }

    if (
      projectTypeVar &&
      refetchQuerysWithProjectTypeParam.includes(api.endpoint)
    ) {
      url.searchParams.set('project_type', ProductsTypeBack[projectTypeVar]);
    }

    const updatedArgs = { ...args, url: url.toString() };

    const result = await fetchBaseQuery({
      baseUrl,
      prepareHeaders,
    })(updatedArgs, api, extraOptions);

    if (result.error?.status === 400) {
      retry.fail(result.error);
    }

    if (result.error?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const tokens = await refreshToken(refresh);
          if (tokens.error?.status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            localStorage.removeItem('LATEST_VIEWED_PROJECT_ID');
            window.location.assign(PATH_AUTH_SPA.ROOT);
            retry.fail(result.error);
          }

          if (tokens.access && tokens.refresh) {
            localStorage.setItem('user', JSON.stringify(tokens));
          } else {
            localStorage.removeItem('user');
            window.location.assign(PATH_AUTH_SPA.ROOT);
            return tokens;
          }
        } catch (error) {
          return console.log('staggeredBaseQueryWithBailOut error', error);
        } finally {
          isRefreshing = false;
        }
      }
    }

    if (['FETCH_ERROR', 500].includes(result.error?.status)) {
      result.error.error =
        'Yikes! It seems our server is taking a coffee break. Please refresh the page.';
    }

    return result;
  },
  {
    maxRetries: 2,
  },
);

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: [
    'User',
    'Projects',
    'UserMe',
    'Accounts',
    'ProjectById',
    'ProjectTeamMembers',
    'CreateUser',
    'PaymentMethods',
    'Payments',
    'ProjectPlans',
    'ProjectIntegration',
    'ProjectFiles',
    'SubscriptionPermission',
  ],
  endpoints: () => ({}),
});
