import './Input.css';
import React, { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { CustomSelect } from '../Select/Select';
import { clsx } from 'clsx';
import { uuid } from 'src/utils/uuid';

const PasswordEye = ({ onClick, readonly }) => {
  const [visible, setVisible] = useState(false);
  const toggleEye = () => {
    if (readonly) {
      return;
    }
    setVisible(!visible);
    onClick && onClick();
  };

  return (
    <>
      <div
        onClick={toggleEye}
        className={clsx(visible ? 'visible eye' : 'eye', 'z-50')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle cx="10" cy="10" r="5" fill="#75F3FB" />
          <path
            d="M9.99968 3.75C5.83301 3.75 2.27467 6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99968 16.25C14.1663 16.25 17.7247 13.6583 19.1663 10C17.7247 6.34167 14.1663 3.75 9.99968 3.75ZM9.99968 14.1667C7.69968 14.1667 5.83301 12.3 5.83301 10C5.83301 7.7 7.69968 5.83333 9.99968 5.83333C12.2997 5.83333 14.1663 7.7 14.1663 10C14.1663 12.3 12.2997 14.1667 9.99968 14.1667ZM9.99968 7.5C8.61634 7.5 7.49968 8.61667 7.49968 10C7.49968 11.3833 8.61634 12.5 9.99968 12.5C11.383 12.5 12.4997 11.3833 12.4997 10C12.4997 8.61667 11.383 7.5 9.99968 7.5Z"
            fill="#05050D"
          />
        </svg>
      </div>
    </>
  );
};
const Input = ({
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  isValid,
  label,
  invalidMessage,
  icon,
  readonly = false,
  autoFocus,
  selectOptions = [],
  inputmode,
  pattern,
  autocomplete,
  maxlength,
  dropdownMenuClassNames = '',
}) => {
  const isPassword = type === 'password';
  const [inputType, setInputType] = useState(type);
  const id = label ? label.replace(/\s+/g, '-').toLowerCase() : uuid();
  const togglePassword = () => {
    if (readonly) {
      return;
    }
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <React.Fragment>
      {label ? <label htmlFor={`#${id}`}>{label}</label> : null}
      <div className={'input-block flex items-center'}>
        {type === 'phone' && (
          <PhoneInput
            defaultCountry="us"
            className={`input ${isValid ? 'valid' : 'invalid'}`}
            placeholder={placeholder}
            value={value}
            onChange={(phone) => onChange(phone)}
            onBlur={onBlur}
            disabled={readonly}
            id={id}
            inputProps={autoFocus ? { autoFocus: autoFocus } : null}
            onFocus={onFocus}
          />
        )}
        {type === 'textarea' && (
          <textarea
            id={id}
            className={`input ${isValid ? 'valid' : 'invalid'}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={readonly}
            autoFocus={autoFocus}
            rows="4"
          />
        )}
        {(type === 'text' ||
          type === 'password' ||
          type === 'email' ||
          type === 'tel') && (
          <input
            id={id}
            className={`input ${isValid ? 'valid' : 'invalid'}`}
            type={inputType}
            inputMode={inputmode}
            pattern={pattern}
            autoComplete={autocomplete}
            maxLength={maxlength}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={readonly}
            autoFocus={autoFocus}
          />
        )}
        {type === 'select' && (
          <CustomSelect
            dropdownMenuClassNames={dropdownMenuClassNames}
            options={selectOptions}
            value={value}
            onChange={onChange}
          />
        )}
        {isValid ? null : (
          <div
            className={clsx('error-message px-3.5 py-0', isPassword && 'pr-10')}
          >
            {invalidMessage}
          </div>
        )}
        {isPassword ? (
          <PasswordEye onClick={togglePassword} readonly={readonly} />
        ) : null}
        {icon ? icon : null}
      </div>
    </React.Fragment>
  );
};

export default Input;
