import { useState, useEffect, useCallback } from 'react';

const useBroadcast = (config = {}) => {
  const { onMessage, channelName = 'default_channel' } = config;
  const [channelInstance, setChannelInstance] = useState(null);

  const handleMessage = useCallback(
    (event) => {
      if (onMessage) {
        onMessage(event);
      }
    },
    [onMessage],
  );

  useEffect(() => {
    const channel = new BroadcastChannel(channelName);
    channel.addEventListener('message', handleMessage);

    setChannelInstance(channel);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [channelName, handleMessage]);

  return { channelInstance };
};

export default useBroadcast;
