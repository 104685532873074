import React from 'react';
import './Spinner.css';
import { Popover, PopoverContent } from 'src/components/ui/popover';

export const Spinner = ({ fullScreen = false }) => {
  return fullScreen ? (
    <Popover modal open>
      <PopoverContent className="fixed bg-transparent border-none">
        <div className="spinner-wrapper w-screen h-screen">
          <div className={'spinner'}></div>
        </div>
      </PopoverContent>
    </Popover>
  ) : (
    <div className={'spinner'}></div>
  );
};
