import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { clsx } from 'clsx';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { ProductItemsIcons } from 'src/constants/products';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { StatusBadgeColors } from 'src/components/StatusBadgeColors/StatusBadgeColors';
import { ProjectPlanStatus } from 'src/constants/projects';

const IIcon = ({ text, isOutOfLimit }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <svg
          className="cursor-pointer min-w-3.5"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.41663 9.91669H7.58329V6.41669H6.41663V9.91669ZM6.99996 5.25002C7.16524 5.25002 7.30388 5.19402 7.41588 5.08202C7.52788 4.97002 7.58368 4.83158 7.58329 4.66669C7.5829 4.5018 7.5269 4.36335 7.41529 4.25135C7.30368 4.13935 7.16524 4.08335 6.99996 4.08335C6.83468 4.08335 6.69624 4.13935 6.58463 4.25135C6.47302 4.36335 6.41702 4.5018 6.41663 4.66669C6.41624 4.83158 6.47224 4.97021 6.58463 5.0826C6.69702 5.19499 6.83546 5.2508 6.99996 5.25002ZM6.99996 12.8334C6.19302 12.8334 5.43468 12.6801 4.72496 12.3737C4.01524 12.0672 3.39788 11.6517 2.87288 11.1271C2.34788 10.6025 1.93235 9.98513 1.62629 9.27502C1.32024 8.56491 1.16702 7.80657 1.16663 7.00002C1.16624 6.19346 1.31946 5.43513 1.62629 4.72502C1.93313 4.01491 2.34865 3.39755 2.87288 2.87294C3.3971 2.34833 4.01446 1.9328 4.72496 1.62635C5.43546 1.31991 6.19379 1.16669 6.99996 1.16669C7.80613 1.16669 8.56446 1.31991 9.27496 1.62635C9.98546 1.9328 10.6028 2.34833 11.127 2.87294C11.6513 3.39755 12.067 4.01491 12.3742 4.72502C12.6814 5.43513 12.8345 6.19346 12.8333 7.00002C12.8321 7.80657 12.6789 8.56491 12.3736 9.27502C12.0683 9.98513 11.6528 10.6025 11.127 11.1271C10.6013 11.6517 9.9839 12.0674 9.27496 12.3743C8.56601 12.6811 7.80768 12.8341 6.99996 12.8334ZM6.99996 11.6667C8.30274 11.6667 9.40621 11.2146 10.3104 10.3104C11.2145 9.40627 11.6666 8.3028 11.6666 7.00002C11.6666 5.69724 11.2145 4.59377 10.3104 3.6896C9.40621 2.78544 8.30274 2.33335 6.99996 2.33335C5.69718 2.33335 4.59371 2.78544 3.68954 3.6896C2.78538 4.59377 2.33329 5.69724 2.33329 7.00002C2.33329 8.3028 2.78538 9.40627 3.68954 10.3104C4.59371 11.2146 5.69718 11.6667 6.99996 11.6667Z"
            fill={isOutOfLimit ? '#FF5151' : '#05050D'}
            stroke={isOutOfLimit ? '#FF5151' : '#05050D'}
            strokeWidth="0.5"
          />
        </svg>
      </PopoverTrigger>
      <PopoverContent className="px-3.5 py-3 w-fit max-w-36">
        <p
          className={clsx(
            isOutOfLimit
              ? 'text-error text-xs font-semibold'
              : 'text-xs opacity-70',
          )}
        >
          {text}
        </p>
      </PopoverContent>
    </Popover>
  );
};

export const SubscriptionOverviewList = ({
  currentProject,
  isEmpty,
  isFetchingProject,
}) => {
  const projectPlan = [currentProject?.project_plan];
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="px-2.5">Plan</TableHead>
          <TableHead className="text-center px-2.5">Status</TableHead>
          <TableHead className="px-2.5">
            {[ProjectPlanStatus.CANCELED, ProjectPlanStatus.INACTIVE].includes(
              projectPlan[0]?.status,
            ) ? (
              <span className="text-error">Ended on</span>
            ) : (
              'Active till'
            )}
          </TableHead>
          <TableHead className="px-2.5">AI replies balance</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody
        className={clsx(
          isFetchingProject &&
            projectPlan?.length &&
            'animate-pulse pointer-events-none blur-sm',
        )}
      >
        {isEmpty ? (
          currentProject &&
          projectPlan.map((plan) => {
            const usedRequests =
              Number(plan?.plan?.request_limit) -
              Number(currentProject?.requests_left) +
              Number(currentProject?.credit_count);
            const isOutOfLimit =
              Number(plan?.plan?.request_limit) < usedRequests;
            return (
              <TableRow key={plan.id} className={clsx('transition')}>
                <TableCell
                  className="font-medium table-fixed md:h-[60px] md:w-[50px] h-[55px] w-[45px] min-w-[45px]"
                  padding="p-2.5 pr-0"
                >
                  <div className="flex items-center md:gap-3 gap-2 font-semibold text-base text-ebony hyphens-auto">
                    {ProductItemsIcons[currentProject.project_type]}
                    <span className="truncate">{plan.plan.name}</span>
                  </div>
                </TableCell>
                <TableCell padding="p-2.5" className="text-center">
                  <div className="justify-center text-base flex items-center gap-2.5">
                    <div className="integration-card-status">
                      <StatusBadgeColors status={plan.status} />
                    </div>
                  </div>
                </TableCell>
                <TableCell padding="p-2.5">
                  <div className="col font-medium text-base text-ebony hyphens-auto">
                    <p>
                      {plan?.current_period_end &&
                        transformDateHelper(
                          plan?.current_period_end,
                          DateFormat.MONTH_DAY_YEAR,
                        )}
                    </p>
                  </div>
                </TableCell>
                <TableCell padding="p-2.5">
                  <div className="flex gap-1 items-center font-bold text-base text-ebony hyphens-auto">
                    <p
                      className={clsx(
                        'flex items-center gap-1',
                        isOutOfLimit && 'text-error',
                      )}
                    >
                      {usedRequests}
                    </p>
                    <IIcon
                      isOutOfLimit={isOutOfLimit}
                      text={
                        isOutOfLimit
                          ? 'You use extra replies now.'
                          : 'AI replies sent'
                      }
                    />
                    /
                    <p className="flex items-center gap-1">
                      {plan?.plan?.request_limit}
                    </p>
                    <IIcon text="A replies included in service plan" />
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              colSpan={4}
              padding="p-2.5"
              className="font-medium table-fixed max-w-80"
            >
              <div className="col md:h-10 h-8 flex items-center">
                <LoaderWrapper
                  loader={
                    <div className="flex md:gap-10 gap-4 items-center w-full">
                      <Skeleton className="h-6 w-full" />
                    </div>
                  }
                  isLoading={isFetchingProject}
                >
                  <p className="font-medium text-base text-ebony opacity-70">
                    There is no subscription yet.
                  </p>
                </LoaderWrapper>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
