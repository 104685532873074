import './AnalyticsAgent.css';
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import AnimatedNumber from 'animated-number-react';
import { useFetchAnalyticsMutation } from 'src/services/analyticsService';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { AreaCharts } from './AreaChart/AreaChart';
import Multiselect from 'multiselect-react-dropdown';
import constants from '../constants';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ProjectSelectFilterPopup } from 'src/components/ProjectSelectFilterPopup/ProjectSelectFilterPopup';
import { useTheme } from 'src/provider/ThemeProvider';
import { clsx } from 'clsx';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useGetProjectTeamMembersQuery } from 'src/services';
import { useParams } from 'react-router-dom';
import { DatePicker } from 'src/components/DatePicker/DatePicker';
import {
  getAnalyticsPageConfig,
  setAnalyticsPageConfig,
} from 'src/utils/pageStorageConfig';
// import {BarChart2} from "./BarChart/BarChart";

const AnalyticsAgent = () => {
  const notification = useNotification();
  const loader = useLoader();
  const [teamMembers, setTeamMembers] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [incomingTickets, setIncomingTickets] = useState(0);
  const [resolvedTickets, setResolvedTickets] = useState(0);
  const [averageFirstReplyTime, setAverageFirstReplyTime] = useState(0);
  const [averageFullResolutionTime, setAverageFullResolutionTime] = useState(0);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [request, setRequest] = useState(constants.initialRequest);
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { setTheme } = useTheme();
  const { id } = useParams();
  const [
    fetchAnalytics,
    {
      data: fetchAnalyticsRes,
      isLoading,
      isError: isFetchAnalyticsError,
      error: fetchAnalyticsError,
      isSuccess,
      isFetching,
    },
  ] = useFetchAnalyticsMutation();
  // const { projectId = projects[0].id, projectType } = useAppSelector(
  //   (s) => s.projectsSlices,
  // );
  const {
    data: projectTeamMembersResponse,
    error: errorGetProjectTeamMembersFiles,
    isError: isErrorGetProjectTeamMembers,
    isLoading: isLoadingGetProjectTeamMembers,
    refetch: refetchGetProjectTeamMembers,
  } = useGetProjectTeamMembersQuery(
    {
      id,
    },
    { skip: !id },
  );

  const agentRef = useRef();
  const formatSummary = (value) => parseInt(value.toFixed(0));

  // use MutationObserver to track on dropdown list closed
  useEffect(() => {
    if (!agentRef.current) {
      return;
    }

    const observer = new MutationObserver(() => {
      const isHidden =
        agentRef.current.searchWrapper.current.nextElementSibling.classList.contains(
          'displayNone',
        );

      if (isHidden) {
        const agents = agentRef.current.getSelectedItems();

        if (!agents.length) {
          return;
        }

        const isSubdomainMetric = request.metrics[0].startsWith('subdomain_');
        const isTeamPerformance = agents[0].full_name === 'Team Performance';
        let newRequest;

        if (isTeamPerformance) {
          newRequest = {
            ...request,
            metrics: isSubdomainMetric
              ? request.metrics
              : request.metrics.map((metric) => `subdomain_${metric}`),
            agents: teamMembers,
          };
        } else {
          newRequest = {
            ...request,
            metrics: isSubdomainMetric
              ? request.metrics.map((metric) =>
                  metric.replace('subdomain_', ''),
                )
              : request.metrics,
            agents: agents,
          };
        }

        newRequest.agents = newRequest.agents
          .map((user) => {
            const currentProjectCrmData = user.crm_data.find(
              (data) => data.project_id === +id,
            );

            return currentProjectCrmData && currentProjectCrmData.crm_user_id;
          })
          .filter(Boolean);

        const { request: storageRequest } = getAnalyticsPageConfig();

        if (JSON.stringify(storageRequest) === JSON.stringify(newRequest)) {
          return;
        }

        setRequest(newRequest);
      }
    });
    observer.observe(
      agentRef.current.searchWrapper.current.nextElementSibling,
      { attributes: true },
    );

    return () => observer.disconnect();
  });

  useEffect(() => {
    if (!projectTeamMembersResponse) {
      return;
    }

    const crmUsers = projectTeamMembersResponse.filter((user) => {
      const crmData = user.user.crm_data || [];
      const currentProjectCrmData = crmData.find(
        (data) => data.project_id === +id,
      );

      return Boolean(
        currentProjectCrmData && currentProjectCrmData.crm_user_id,
      );
    });

    setTeamMembers(
      crmUsers.map(({ user }) => {
        return {
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        };
      }),
    );

    const agents = crmUsers.map((user) => {
      const crmData = user.user.crm_data;
      const currentProjectCrmData = crmData.find(
        (data) => data.project_id === +id,
      );

      return currentProjectCrmData.crm_user_id;
    });

    setRequest({
      ...request,
      agents,
      project_id: +id,
    });
  }, [id, projectTeamMembersResponse]);

  useEffect(() => {
    const isEmptyAgent = !Boolean(request.agents && request.agents.length);

    setAnalyticsPageConfig({ request });

    if (isEmptyAgent) {
      return clearPage();
    }

    fetchAnalytics(request);
  }, [request]);

  useEffect(() => {
    isLoading
      ? loader.show()
      : !isLoadingGetProjectTeamMembers && loader.hide();
  }, [isLoading]);

  useEffect(() => {
    isLoadingGetProjectTeamMembers
      ? loader.show()
      : !isLoading && loader.hide();
  }, [isLoadingGetProjectTeamMembers]);

  useEffect(() => {
    if (isSuccess) {
      let parsedResponse;
      const isEmptyData = !Boolean(
        fetchAnalyticsRes.data && Object.keys(fetchAnalyticsRes.data).length,
      );
      const subdomain = fetchAnalyticsRes?.meta?.subdomain;

      const tickets = {
        resolved:
          subdomain?.closed +
            subdomain?.hold +
            subdomain?.pending +
            subdomain?.solved || 0,
        incoming: subdomain?.open + subdomain?.new || 0,
      };

      if (isEmptyData) {
        clearPage();
        setResolvedTickets(tickets.resolved);
        setIncomingTickets(tickets.incoming);
        return;
      }
      setResolvedTickets(tickets.resolved);
      setIncomingTickets(tickets.incoming);
      setAverage(fetchAnalyticsRes.average);

      if (fetchAnalyticsRes.isByUser) {
        parsedResponse = {};

        for (let key in fetchAnalyticsRes.parsedResponse) {
          const userStatistic = fetchAnalyticsRes.parsedResponse[key];
          const teamMember = teamMembers.find((teamMember) => {
            return teamMember.crm_data.find(
              (crmData) => crmData.crm_user_id === key,
            );
          });
          const fullName = `${teamMember.first_name} ${teamMember.last_name}`;

          parsedResponse[fullName] = userStatistic;
        }
      } else {
        parsedResponse = fetchAnalyticsRes.parsedResponse;
      }

      setAnalyticsData(parsedResponse);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isFetchAnalyticsError) {
      notification.error(getErrorMessage(fetchAnalyticsError));
    }
  }, [isFetchAnalyticsError]);

  useEffect(() => {
    if (isErrorGetProjectTeamMembers) {
      notification.error(getErrorMessage(errorGetProjectTeamMembersFiles));
    }
  }, [isErrorGetProjectTeamMembers]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  const setAverage = (average = false) => {
    if (average) {
      if (average[constants.ticket_closed_time]) {
        setAverageFullResolutionTime(average[constants.ticket_closed_time]);
      }

      if (average[constants.first_reply_time]) {
        setAverageFirstReplyTime(average[constants.first_reply_time]);
      }

      if (average[constants.reply_time]) {
        setAverageResponseTime(average[constants.reply_time]);
      }
    } else {
      setAverageFullResolutionTime(0);
      setAverageFirstReplyTime(0);
      setAverageResponseTime(0);
    }
  };

  const clearPage = () => {
    setAnalyticsData(null);
    setIncomingTickets(0);
    setResolvedTickets(0);
    setAverage(0);
  };

  const onFiltersPopupClose = async (request) => {
    const agents = agentRef.current.getSelectedItems();
    const isTeamPerformance = agents[0].full_name === 'Team Performance';
    const isSubdomainMetric = request.metrics[0].startsWith('subdomain_');

    if (isTeamPerformance) {
      setRequest({
        ...request,
        metrics: isSubdomainMetric
          ? request.metrics
          : request.metrics.map((metric) => `subdomain_${metric}`),
      });

      return;
    }

    setRequest({
      ...request,
      metrics: isSubdomainMetric
        ? request.metrics.map((metric) => metric.replace('subdomain_', ''))
        : request.metrics,
    });
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('analytics-page-container', !isLgDown && 'relative')}
        showFilter
        mobileFilterModal={<ProjectSelectFilterPopup id={id} />}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="dashboard z-10">
            <div className="card-header">
              <div className="title">Dashboard</div>
              <div className="flex flex-row gap-[10px]">
                <div>
                  <Multiselect
                    ref={agentRef}
                    className={'agents-multiselect'}
                    options={[
                      { full_name: 'Team Performance' },
                      ...teamMembers,
                    ]} // Options to display in the dropdown
                    showCheckbox={true}
                    avoidHighlightFirstOption={true}
                    closeOnSelect={false}
                    hidePlaceholder={true}
                    placeholder={'Please Select Agents'}
                    selectedValues={[{ full_name: 'Team Performance' }]} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      if (selectedItem.full_name === 'Team Performance') {
                        selectedList.length = 0;
                        selectedList.push(selectedItem);
                        return;
                      }

                      if (selectedList[0].full_name === 'Team Performance') {
                        selectedList.shift();
                      }
                    }}
                    selectedValueDecorator={(string) =>
                      string === 'Team Performance'
                        ? string
                        : string.split(' ')[0]
                    }
                    displayValue="full_name"
                  />
                </div>
                <div className="date-container">
                  {`${moment(request.start_date).format('ll')} - ${moment(request.end_date).format('ll')}`}
                </div>
                <div className="date-container">
                  <DatePicker />
                </div>
              </div>
            </div>
            <div className="card-body md:flex-row flex-col md:gap-[10px] gap-[6px] size-full">
              <div className="md:flex-row flex flex-col row size-full md:gap-[10px] gap-[6px]">
                <div className="summary-card pale-green flex flex-col justify-between">
                  <div className="summary-card-title">Incoming Tickets</div>
                  <div className="summary-card-value text-ebony">
                    <AnimatedNumber
                      value={incomingTickets}
                      formatValue={formatSummary}
                    />
                  </div>
                </div>
                <div className="summary-card pale-green flex flex-col justify-between">
                  <div className="summary-card-title">Resolved Tickets</div>
                  <div className="summary-card-value text-ebony">
                    <AnimatedNumber
                      value={resolvedTickets}
                      formatValue={formatSummary}
                    />
                  </div>
                </div>
                {Boolean(averageFirstReplyTime) && (
                  <div className="summary-card pale-cyan flex flex-col justify-between">
                    <div className="summary-card-title">
                      Average first <br /> response time
                    </div>
                    <div className="summary-card-value text-ebony">
                      {averageFirstReplyTime}
                    </div>
                  </div>
                )}
                {Boolean(averageResponseTime) && (
                  <div className="summary-card pale-cyan flex flex-col justify-between">
                    <div className="summary-card-title">
                      Average response <br /> time
                    </div>
                    <div className="summary-card-value text-ebony">
                      {averageResponseTime}
                    </div>
                  </div>
                )}
                {Boolean(averageFullResolutionTime) && (
                  <div className="summary-card pale-cyan flex flex-col justify-between">
                    <div className="summary-card-title">
                      Average full <br /> resolution time
                    </div>
                    <div className="summary-card-value text-ebony">
                      {averageFullResolutionTime}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body md:flex-row flex-col md:gap-[10px] gap-[6px] size-full">
              111111
            </div>
          </CardContainer>
          {request.metrics.map((metric, index) => {
            return (
              <CardContainer
                key={metric}
                className={index ? 'flex-1' : 'w-full'}
              >
                <div className="card-header">
                  <div className="title">{constants[metric]}</div>
                  <div className="edit-button-container"></div>
                </div>
                <div className="card-body">
                  {/*<div className={'controls'}>*/}
                  {/*  <FiltersPopup*/}
                  {/*    request={request}*/}
                  {/*    onClose={onFiltersPopupClose}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className={'chart h-full w-full mt-4'}>
                    {request.data_visualization === 'linear' &&
                      analyticsData && (
                        <AreaCharts
                          request={request}
                          data={analyticsData}
                          metric={metric}
                        />
                      )}
                    {/*{(request.data_visualization === 'histogram' && analyticsData) && <BarChart2 request={request} data={analyticsData}/> }*/}
                  </div>
                </div>
                {!analyticsData && (
                  <div className={'card-body'}>
                    <div className={'dashboard-item'}>
                      <p>To display summary, start working in this project.</p>
                    </div>
                  </div>
                )}
                <div className="card-footer"></div>
              </CardContainer>
            );
          })}
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AnalyticsAgent;
