import React from 'react';

export const CalendarIcon = () => (
  <svg
    className="calendar-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M2.60352 5.84985H3.90352V7.14985H2.60352V5.84985ZM2.60352 8.44985H3.90352V9.74985H2.60352V8.44985ZM5.20352 5.84985H6.50352V7.14985H5.20352V5.84985ZM5.20352 8.44985H6.50352V9.74985H5.20352V8.44985ZM7.80352 5.84985H9.10352V7.14985H7.80352V5.84985ZM7.80352 8.44985H9.10352V9.74985H7.80352V8.44985Z"
      fill="#05050D"
    />
    <path
      d="M1.3 13H10.4C11.117 13 11.7 12.4169 11.7 11.7V2.6C11.7 1.88305 11.117 1.3 10.4 1.3H9.1V0H7.8V1.3H3.9V0H2.6V1.3H1.3C0.58305 1.3 0 1.88305 0 2.6V11.7C0 12.4169 0.58305 13 1.3 13ZM10.4 3.9L10.4007 11.7H1.3V3.9H10.4Z"
      fill="#05050D"
    />
  </svg>
);
