import './Checkbox.css';
import React, { useRef } from 'react';
import { uuid } from 'src/utils/uuid';

export const Checkbox = ({ label, checked, onChange }) => {
  const id = label ? label.replace(/\s+/g, '-').toLowerCase() : uuid();
  const inputRef = useRef(null);

  return (
    <>
      <div className="checkbox-container">
        <input
          ref={inputRef}
          className={'checkbox-input'}
          type="checkbox"
          id={`${id}-checkbox`}
          checked={checked}
          onChange={onChange}
        />
        <span onClick={() => inputRef.current.click()}></span>
        <label htmlFor={`${id}-checkbox`}>{label}</label>
      </div>
    </>
  );
};
