export const refetchTagsWithAccountParam = [
  'Projects',
  'Accounts',
  'UsersAll',
  'ProjectById',
];

export const refetchTagsWithAccountParamForSubscription = [
  'PaymentMethods',
  'Payments',
  'ProjectPlans',
  'SubscriptionPermission',
];

export const refetchQuerysWithAccountParam = [
  'getAllProjects',
  'getUsersAll',
  'getAccounts',
  'getProjectById',
  'createProject',
  'deleteProject',
  'updateAccounts',
  'updateProject',
  'addProjectTeamMembers',
  'createUser',
  'deleteProjectTeamMembers',
  'updateUser',
  'getProjectTeamMembers',
  'newLimitProjectTeamMembers',
  'getPaymentMethods',
  'setDefaultPaymentMethod',
  'getPayments',
  'getAllPlans',
  'createProjectPlans',
  'getProjectsSubscriptions',
  'createSubscription',
  'deleteSubscription',
  'cancelSubscription',
  'subscriptionAddItem',
  'getInvoicePdf',
  'deletePaymentMethods',
  'extendProjectPlan',
  'deExtendProjectPlan',
  'getSubscriptionPermission',
  'setSubscriptionPermission',
  'activateProject',
  'deactivateProject',
  'deleteIntegration',
  'addIntegration',
  'updateIntegration',
  'changeSubscription',
  'getProjectFiles',
  'addProjectFile',
  'addProjectDocument',
  'updateProjectFiles',
  'deleteProjectFiles',
  'deleteProjectFile',
  'fetchAnalytics',
];

export const refetchQuerysWithProjectTypeParam = [
  'getProjectById',
  'updateProject',
  'deleteProject',
  'createProject',
  'addProjectTeamMembers',
  'getProjectTeamMembers',
  'deleteProjectTeamMembers',
  'newLimitProjectTeamMembers',
  'activateProject',
  'deactivateProject',
  'addIntegration',
  'updateIntegration',
  'deleteIntegration',
  'getProjectFiles',
  'addProjectFile',
  'addProjectDocument',
  'updateProjectFiles',
  'deleteProjectFiles',
  'deleteProjectFile',
  'fetchAnalytics',
];
