import React, { useEffect } from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { clsx } from 'clsx';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { ProductItemsIcons, ProductItemsLabels } from 'src/constants/products';
import {
  useCancelSubscriptionMutation,
  useDeExtendProjectPlanMutation,
  useDeleteSubscriptionMutation,
  useExtendProjectPlanMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { Button } from 'src/components/ui/button';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { CheckIcon } from 'src/assets/icons/checkIcon';
import { CancelIcon } from 'src/assets/icons/cancelIcon';
import { ExtendCurrentSubscriptionPlanPopup } from 'src/pages/SubscriptionPage/CurrentSubscriptionPlansSection/ExtendCurrentSubscriptionPlanPopup/ExtendCurrentSubscriptionPlanPopup';
import { EditSubscriptionPopup } from 'src/components/EditSubscriptionPopup/EditSubscriptionPopup';
import { EditIcon } from 'src/assets/icons/editIcon';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';

export const CurrentSubscriptionPlans = ({
  projectsSubscriptions,
  projectsSubscriptionsIsFetching,
  projSubscriptions = [],
}) => {
  const notification = useNotification();
  const loader = useLoader();
  const isMdDown = useMediaQuery('(max-width: 767px)');

  const { data: userInfo } = useGetUsersMeQuery();

  const {
    data: account,
    error: accountError,
    isError: accountIsError,
    isFetching: accountIsFetching,
  } = useGetAccountsQuery();

  const [
    deleteSubscription,
    {
      isLoading: deleteSubscriptionIsLoading,
      isError: deleteSubscriptionIsError,
      error: deleteSubscriptionError,
      isSuccess: deleteSubscriptionIsSuccess,
    },
  ] = useDeleteSubscriptionMutation();

  const [
    cancelSubscription,
    {
      isLoading: cancelSubscriptionIsLoading,
      isError: cancelSubscriptionIsError,
      error: cancelSubscriptionError,
      isSuccess: cancelSubscriptionIsSuccess,
    },
  ] = useCancelSubscriptionMutation();

  const [
    extendProjectPlan,
    {
      data: extendProjectPlanData,
      isLoading: extendProjectPlanIsLoading,
      isError: extendProjectPlanIsError,
      error: extendProjectPlanError,
      isSuccess: extendProjectPlanIsSuccess,
    },
  ] = useExtendProjectPlanMutation();

  const [
    deExtendProjectPlan,
    {
      data: deExtendProjectPlanData,
      isLoading: deExtendProjectPlanIsLoading,
      isError: deExtendProjectPlanIsError,
      error: deExtendProjectPlanError,
      isSuccess: deExtendProjectPlanIsSuccess,
    },
  ] = useDeExtendProjectPlanMutation();

  useEffect(() => {
    if (
      deleteSubscriptionIsLoading ||
      cancelSubscriptionIsLoading ||
      extendProjectPlanIsLoading ||
      deExtendProjectPlanIsLoading
    ) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [
    deleteSubscriptionIsLoading,
    extendProjectPlanIsLoading,
    cancelSubscriptionIsLoading,
    deExtendProjectPlanIsLoading,
  ]);

  useEffect(() => {
    if (deleteSubscriptionIsError) {
      notification.error(getErrorMessage(deleteSubscriptionError));
    }
    if (cancelSubscriptionIsError) {
      notification.error(getErrorMessage(cancelSubscriptionError));
    }
    if (deExtendProjectPlanIsError) {
      notification.error(getErrorMessage(deExtendProjectPlanError));
    }
    if (extendProjectPlanIsError) {
      notification.error(getErrorMessage(extendProjectPlanError));
    }
  }, [
    deleteSubscriptionIsError,
    cancelSubscriptionIsError,
    deExtendProjectPlanIsError,
    extendProjectPlanIsError,
  ]);

  useEffect(() => {
    if (deleteSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully deleted!');
    }
    if (cancelSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully canceled!');
    }
  }, [deleteSubscriptionIsSuccess, cancelSubscriptionIsSuccess]);

  useEffect(() => {
    if (deExtendProjectPlanIsSuccess) {
      notification.success(getErrorMessage(deExtendProjectPlanData));
    }
  }, [deExtendProjectPlanIsSuccess]);

  useEffect(() => {
    if (extendProjectPlanIsSuccess) {
      notification.success(getErrorMessage(extendProjectPlanData));
    }
  }, [extendProjectPlanIsSuccess]);

  const deleteSubscriptionHandler = (id, targetPlan) => {
    deleteSubscription({
      project_plan_id: Number(id),
      target_service: targetPlan,
    });
  };

  const cancelSubscriptionHandler = () => {
    cancelSubscription();
  };

  const extendHandler = (id, targetPlan) => {
    extendProjectPlan({
      project_id: Number(id),
      target_service: targetPlan,
    });
  };

  const deExtendHandler = (id, targetPlan) => {
    deExtendProjectPlan({
      project_id: Number(id),
      target_service: targetPlan,
    });
  };

  const isEmpty = !Boolean(
    projSubscriptions?.length && projectsSubscriptions.length,
  );

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return isEmpty ||
    (!projSubscriptions && projectsSubscriptionsIsFetching) ||
    accountIsFetching ? (
    <div className="empty-message">
      <LoaderWrapper
        loader={<Skeleton className="w-full h-5" />}
        isLoading={projectsSubscriptionsIsFetching}
      >
        Add subscription plan.
      </LoaderWrapper>
    </div>
  ) : (
    <section
      className={clsx(
        'team-list transition',
        projectsSubscriptionsIsFetching &&
          'animate-pulse blur-sm pointer-events-none',
      )}
    >
      {isMdDown ? (
        <div className="gap-[6px] flex flex-col mt-[10px]">
          <DeletePopup
            disabled={
              accountIsFetching ||
              !account?.stripe_subscription_id ||
              accountIsError ||
              !isCosupportManager
            }
            title="Cancel subscriptions?"
            description="Are you sure you want to cancel subscription plan?"
            triggerIcon={<Button variant="outline">Cancel all</Button>}
            deleteBtnText="Yes, cancel"
            deleteHandler={cancelSubscriptionHandler}
          />
          {projSubscriptions &&
            projSubscriptions.map((subscriptionProject) => {
              return (
                <div
                  key={subscriptionProject.id}
                  className={clsx(
                    'flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border',
                    subscriptionProject.project_plan.status !== 'active' &&
                      '[&_.item:not(:first-child)]:opacity-50',
                  )}
                >
                  <div className="item text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
                    <div className="col name md:text-base text-sm flex justify-between">
                      <div className="flex items-center gap-[8px]">
                        <p className="hyphens-auto whitespace-pre-wrap">
                          {subscriptionProject?.project_plan?.plan?.name}
                        </p>
                      </div>
                      <div className="flex gap-2 flex-nowrap items-center">
                        {isCosupportManager && (
                          <div className="text-right">
                            <div className="flex gap-2">
                              <ExtendCurrentSubscriptionPlanPopup
                                disabled={
                                  subscriptionProject?.is_extended_manually
                                }
                                description={
                                  <p>
                                    Are you sure you want to extend{' '}
                                    <b>{subscriptionProject.name}</b> project?
                                  </p>
                                }
                                triggerIcon={
                                  <Button size="sm" variant="outline">
                                    <CheckIcon />
                                  </Button>
                                }
                                submitHandler={() =>
                                  extendHandler(
                                    subscriptionProject?.id,
                                    subscriptionProject?.project_plan?.plan
                                      ?.target_service,
                                  )
                                }
                              />
                              <ExtendCurrentSubscriptionPlanPopup
                                disabled={
                                  !subscriptionProject?.is_extended_manually
                                }
                                description={
                                  <p>
                                    Are you sure you want to de-extend{' '}
                                    <b>{subscriptionProject.name}</b> project?
                                  </p>
                                }
                                triggerIcon={
                                  <Button size="sm" variant="outline">
                                    <CancelIcon />
                                  </Button>
                                }
                                title="De-extend project?"
                                submitHandler={() =>
                                  deExtendHandler(
                                    subscriptionProject?.id,
                                    subscriptionProject?.project_plan?.plan
                                      ?.target_service,
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                        <EditSubscriptionPopup
                          triggerIcon={
                            <div className="edit-button">
                              <EditIcon />
                            </div>
                          }
                          project={subscriptionProject}
                          disabled={!isCosupportManager}
                        />
                        <DeletePopup
                          title="Delete subscription plan"
                          description="Are you sure you want to permanently delete this subscription plan?"
                          deleteBtnText="Delete"
                          disabled={!isCosupportManager}
                          deleteHandler={() =>
                            deleteSubscriptionHandler(
                              subscriptionProject?.project_plan.id,
                              subscriptionProject?.project_plan?.plan
                                ?.target_service,
                            )
                          }
                        />
                        {!isCosupportManager && (
                          <WarningPopover triggerClassName="" fill="black" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Product:
                    </span>
                    <p className="truncate flex items-center gap-2">
                      {ProductItemsIcons[subscriptionProject.project_type]}
                      <span className="truncate">
                        {ProductItemsLabels[subscriptionProject.project_type]}
                      </span>
                    </p>
                  </div>
                  <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Project:
                    </span>
                    <p className="truncate">{subscriptionProject.name}</p>
                  </div>
                  <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Active till:
                    </span>
                    <p className="truncate">
                      {subscriptionProject?.project_plan?.current_period_end &&
                        transformDateHelper(
                          subscriptionProject?.project_plan?.current_period_end,
                          DateFormat.MONTH_DAY_YEAR,
                        )}
                    </p>
                  </div>
                  <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Status:
                    </span>
                    <p className="truncate">
                      {subscriptionProject?.project_plan?.status &&
                        subscriptionProject?.project_plan?.status}
                    </p>
                  </div>
                  <div
                    className={clsx(
                      'item flex items-center text-xs p-[15px] gap-[10px]',
                    )}
                  >
                    <p className="font-semibold">
                      {subscriptionProject?.project_plan?.plan?.price} $
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>Project</TableHead>
                <TableHead>Plan</TableHead>
                <TableHead>Price</TableHead>
                <TableHead className="whitespace-nowrap">Active till</TableHead>
                <TableHead>Status</TableHead>
                {isCosupportManager && (
                  <TableHead className="text-center">Extend</TableHead>
                )}
                <TableHead>
                  <div className="flex items-center justify-center gap-1">
                    Edit{' '}
                    {!isCosupportManager && (
                      <WarningPopover triggerClassName="" fill="black" />
                    )}
                  </div>
                </TableHead>
                <TableHead className="text-right">
                  <div className="flex items-center justify-center gap-1">
                    <DeletePopup
                      disabled={
                        accountIsFetching ||
                        !account?.stripe_subscription_id ||
                        accountIsError ||
                        !isCosupportManager
                      }
                      title="Cancel subscriptions?"
                      description="Are you sure you want to cancel subscription plan?"
                      triggerIcon={
                        <Button variant="outline">Cancel all</Button>
                      }
                      deleteBtnText="Yes, cancel"
                      deleteHandler={cancelSubscriptionHandler}
                    />
                    {!isCosupportManager && (
                      <WarningPopover triggerClassName="" fill="black" />
                    )}
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {projSubscriptions &&
                projSubscriptions.map((subscriptionProject) => {
                  return (
                    <TableRow
                      key={subscriptionProject.id}
                      className={
                        subscriptionProject.project_plan.status !== 'active' &&
                        '[&_td:not(:nth-last-child(-n+2))]:opacity-50'
                      }
                    >
                      <TableCell className="font-medium table-fixed max-w-80">
                        <div className="col font-medium text-base text-ebony hyphens-auto">
                          {ProductItemsIcons[subscriptionProject.project_type]}
                          <span className="truncate">
                            {
                              ProductItemsLabels[
                                subscriptionProject.project_type
                              ]
                            }
                          </span>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="col flex-5 font-medium text-base text-ebony hyphens-auto">
                          <p className="truncate">{subscriptionProject.name}</p>
                        </div>
                      </TableCell>
                      <TableCell className="max-w-80">
                        <div className="col font-medium text-base text-ebony hyphens-auto">
                          <p>{subscriptionProject?.project_plan?.plan?.name}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col font-medium text-base text-ebony hyphens-auto">
                          <p>
                            {subscriptionProject?.project_plan?.plan?.price} $
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col font-medium text-base text-ebony hyphens-auto">
                          <p>
                            {subscriptionProject?.project_plan
                              ?.current_period_end &&
                              transformDateHelper(
                                subscriptionProject?.project_plan
                                  ?.current_period_end,
                                DateFormat.MONTH_DAY_YEAR,
                              )}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col font-medium text-base text-ebony hyphens-auto capitalize">
                          <p>
                            {subscriptionProject?.project_plan?.status &&
                              subscriptionProject?.project_plan?.status}
                          </p>
                        </div>
                      </TableCell>
                      {isCosupportManager && (
                        <TableCell className="text-right">
                          <div className="col edit flex-5">
                            <ExtendCurrentSubscriptionPlanPopup
                              disabled={
                                subscriptionProject?.is_extended_manually
                              }
                              description={
                                <p>
                                  Are you sure you want to extend{' '}
                                  <b>{subscriptionProject.name}</b> project?
                                </p>
                              }
                              triggerIcon={
                                <Button size="sm" variant="outline">
                                  <CheckIcon />
                                </Button>
                              }
                              submitHandler={() =>
                                extendHandler(
                                  subscriptionProject?.id,
                                  subscriptionProject?.project_plan?.plan
                                    ?.target_service,
                                )
                              }
                            />
                            <ExtendCurrentSubscriptionPlanPopup
                              disabled={
                                !subscriptionProject?.is_extended_manually
                              }
                              description={
                                <p>
                                  Are you sure you want to de-extend{' '}
                                  <b>{subscriptionProject.name}</b> project?
                                </p>
                              }
                              triggerIcon={
                                <Button size="sm" variant="outline">
                                  <CancelIcon />
                                </Button>
                              }
                              title="De-extend project?"
                              submitHandler={() =>
                                deExtendHandler(
                                  subscriptionProject?.id,
                                  subscriptionProject?.project_plan?.plan
                                    ?.target_service,
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      )}
                      <TableCell className="text-right relative">
                        <div className="col edit flex-5">
                          <EditSubscriptionPopup
                            triggerIcon={
                              <div className="edit-button">
                                <EditIcon />
                              </div>
                            }
                            triggerWarningClassName={'absolute right-0 top-2'}
                            project={subscriptionProject}
                            disabled={!isCosupportManager}
                            hideWarning
                          />
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          <DeletePopup
                            title="Delete subscription plan"
                            description="Are you sure you want to permanently delete this subscription plan?"
                            deleteBtnText="Delete"
                            disabled={!isCosupportManager}
                            deleteHandler={() =>
                              deleteSubscriptionHandler(
                                subscriptionProject?.project_plan.id,
                                subscriptionProject?.project_plan?.plan
                                  ?.target_service,
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
