import moment from 'moment/moment';

const constants = {
  delay_reply_time: 'Delay Reply Time',
  ticket_closed_time: 'Full Resolution Time',
  reply_time: 'Reply Time',
  first_reply_time: 'First Reply Time',
  subdomain_ticket_closed_time: 'Full Resolution Time',
  subdomain_first_reply_time: 'First Reply Time',
  subdomain_delay_reply_time: 'Reply Time',
  subdomain_reply_time: 'Reply Time',
  initialRequest: {
    project_id: null,
    mode: 'by_tracker', // by_tracker | by_ticket
    date_range: 'day',
    time_axis: 'minutes',
    // start_date: moment('05/01/2024').format('YYYY-MM-DD'),
    // end_date: moment('06/30/2024').format('YYYY-MM-DD'),
    start_date: moment().subtract(2, 'months').format('YYYY-MM-DD'),
    end_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    type_of_statistic: 'quantiles',
    quantiles: [],
    metrics: [
      'subdomain_ticket_closed_time',
      'subdomain_first_reply_time',
      'subdomain_reply_time',
      // 'first_reply_time',
      // 'ticket_closed_time',
      // 'reply_time',

      // 'subdomain_delay_reply_time',
      // 'delay_reply_time',
    ],
    agents: [],
    data_visualization: 'linear',
  },
};

export default constants;
