import React from 'react';

export const FilePathArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
  >
    <g opacity="0.5">
      <path d="M2.41415 0.585785L4.82837 3L2.41415 5.41421" stroke="#05050D" />
    </g>
  </svg>
);
