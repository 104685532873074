import React, { useEffect, useMemo } from 'react';
import { clsx } from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useGetAllProjectsQuery } from 'src/services';
import './Menu.css';
import { menuItemsProducts } from 'src/components/Menu/menuItems';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { AddIcon } from 'src/assets/img/add-icon';
import { useAppDispatch, useAppSelector } from 'src/store';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';
import { setProducts, setProjectId } from 'src/store/slices/projectsSlices';
import { Drawer } from 'vaul';
import { ProductCreateModal } from 'src/components/Menu/ProductCreateModal';

export const ProductsItems = ({ isMobile }) => {
  const { data: projects = [] } = useGetAllProjectsQuery();
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const changeProject = (projectId, projectType) => {
    localStorage.setItem(LATEST_VIEWED_PROJECT_ID, projectId);
    dispatch(setProjectId({ projectId, projectType }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const addProjectHandler = (projectType) => {
    dispatch(setProjectId({ projectType }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const ItemMenuWrapper = ({ children }) => {
    return isMobile ? (
      <Drawer.Close asChild>{children}</Drawer.Close>
    ) : (
      children
    );
  };

  const projectsByType = projects?.reduce(
    (acc, project) => {
      if (!acc[project?.project_type]) {
        acc[project.project_type] = [];
      }
      acc[project?.project_type].push(project);
      return acc;
    },
    { agent: [], bi: [], customer: [] },
  );

  const { projectType, products } = useAppSelector((s) => s.projectsSlices);

  const menuItemsProductsFilterByProjects = menuItemsProducts.filter(
    (item) => projectsByType[item.type].length,
  );

  useEffect(() => {
    if (!products && menuItemsProductsFilterByProjects?.length) {
      dispatch(setProducts({ products: menuItemsProductsFilterByProjects }));
    }
  }, [projectsByType, menuItemsProductsFilterByProjects]);

  return (
    <div className="px-6 py-7.5">
      <p className="pb-1.5 pl-2 text-xs">PRODUCTS</p>
      <div className="flex flex-col gap-2.5">
        {products?.map((menuItem, index) => {
          const isActiveTab = projectsByType[menuItem.type].find((el) => {
            return el.id === +id;
          });
          return (
            <Accordion
              disabled={menuItem.disabled}
              key={`${menuItem.id}-${index}`}
              type="single"
              collapsible
              className="w-full"
              defaultValue={
                (isActiveTab && menuItem.title) ||
                (projectType === menuItem.type && menuItem.title)
              }
            >
              <AccordionItem value={menuItem.title} className="border-b-0">
                <AccordionTrigger
                  className={clsx(
                    Boolean(isActiveTab)
                      ? '[&_p]:opacity-100'
                      : '[&_p]:opacity-60',
                    !menuItem.disabled
                      ? '[&_p]:hover:opacity-100 [&_svg:last-child]:hover:opacity-100'
                      : 'opacity-40',
                    'px-2 py-2.5 rounded [&[data-state=open]]:bg-woodsmoke [&_p]:transition-all [&_svg]:transition-all [&[data-state=open]_p]:opacity-100 [&[data-state=open]_svg]:opacity-100 [&_svg:last-child]:opacity-50',
                  )}
                >
                  <div className="flex items-center gap-2.5">
                    {menuItem.icon}
                    <p className="text-white text-lg font-medium text-ellipsis overflow-hidden text-nowrap">
                      {menuItem.title}
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="ml-11 mt-2 flex flex-col gap-1.5 relative">
                    {projectsByType[menuItem.type] &&
                      projectsByType[menuItem.type]?.map((el) => {
                        const activeSlide = +id === el.id;
                        return (
                          <ItemMenuWrapper key={el.id}>
                            <Link
                              onClick={() =>
                                changeProject(el.id, el?.project_type)
                              }
                              className={clsx(
                                activeSlide
                                  ? '[&_span]:opacity-100'
                                  : '[&_span]:opacity-60',
                                '[&_span]:hover:opacity-100 [&_span]:transition-all relative',
                              )}
                              to={`${PATH_DASHBOARD.PROJECTS}/${el.id}?type=${el.project_type}`}
                            >
                              {activeSlide && (
                                <div className="absolute -left-[25px] bottom-[17px] flex flex-col">
                                  <div className="w-px h-screen ml-[0.5px] bg-shark" />
                                  <svg
                                    className=""
                                    width="20"
                                    height="37"
                                    viewBox="0 0 20 37"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 0V26C1 31.5228 5.47715 36 11 36H20"
                                      stroke="#242426"
                                    />
                                  </svg>
                                </div>
                              )}
                              <button
                                className="py-2 text-left max-w-full truncate"
                                title={el.name}
                              >
                                <span
                                  className={clsx(
                                    activeSlide ? menuItem.color : 'text-white',
                                    activeSlide && 'font-bold',
                                    'font-medium text-base',
                                  )}
                                >
                                  {el.name}
                                </span>
                              </button>
                            </Link>
                          </ItemMenuWrapper>
                        );
                      })}
                    <ItemMenuWrapper>
                      <Link
                        onClick={() => addProjectHandler(menuItem.type)}
                        to={`${PATH_DASHBOARD.PROJECTS_NEW}?type=${menuItem.type}`}
                        className="mt-2 w-fit"
                      >
                        <button className="flex items-center justify-center py-2 px-7 gap-2 opacity-40 hover:opacity-100 transition-all rounded border">
                          <AddIcon strokeColor={'#FFFFFF'} />
                          <span className="text-white">Add Project</span>
                        </button>
                      </Link>
                    </ItemMenuWrapper>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}
        <ProductCreateModal projectsByType={projectsByType} />
      </div>
    </div>
  );
};
