import React from 'react';

export const SearchInputIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.7241 17.1932L14.6436 13.1127C15.626 11.8049 16.1564 10.213 16.1546 8.57728C16.1546 4.39919 12.7554 1 8.57728 1C4.39919 1 1 4.39919 1 8.57728C1 12.7554 4.39919 16.1546 8.57728 16.1546C10.213 16.1564 11.8049 15.626 13.1127 14.6436L17.1932 18.7241C17.3998 18.9088 17.6692 19.0073 17.9461 18.9996C18.2231 18.9918 18.4865 18.8783 18.6824 18.6824C18.8783 18.4865 18.9918 18.2231 18.9996 17.9461C19.0073 17.6692 18.9088 17.3998 18.7241 17.1932ZM3.16494 8.57728C3.16494 7.50682 3.48237 6.4604 4.07708 5.57034C4.6718 4.68029 5.51709 3.98657 6.50607 3.57693C7.49504 3.16728 8.58328 3.0601 9.63318 3.26893C10.6831 3.47777 11.6475 3.99325 12.4044 4.75018C13.1613 5.5071 13.6768 6.47149 13.8856 7.52138C14.0945 8.57128 13.9873 9.65952 13.5776 10.6485C13.168 11.6375 12.4743 12.4828 11.5842 13.0775C10.6942 13.6722 9.64774 13.9896 8.57728 13.9896C7.14237 13.9879 5.76672 13.4171 4.75208 12.4025C3.73744 11.3878 3.16666 10.0122 3.16494 8.57728Z"
      fill="#05050D"
      stroke="#05050D"
      strokeWidth="0.2"
    />
  </svg>
);
