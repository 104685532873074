import { PATH_AUTH_SPA } from '../../constants/spa-routes';

export const unauthorizedMiddleware = () => (next) => (action) => {
  if (
    action?.payload?.status === 401 &&
    action?.payload?.data?.message === 'Unauthorized'
  ) {
    localStorage.removeItem('tokens');
    localStorage.removeItem('user');
    localStorage.removeItem('LATEST_VIEWED_PROJECT_ID');
    window.location.assign(PATH_AUTH_SPA.ROOT);
  }

  return next(action);
};
