import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DatePicker.css';
import moment from 'moment';
import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { CalendarIcon } from './CalendarIcon';
import { ExpandCollapseIcon } from './ExpandCollapseIcon';
import Button from '../Button/Button';

export const DatePicker = ({ onChange }) => {
  const [startDate, setStartDate] = useState(moment().format('DD MMM YYYY'));
  const [endDate, setEndDate] = useState(moment().format('DD MMM YYYY'));
  const [isOpened, setIsOpened] = useState(false);
  const [selection, setSelection] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const onChangeHandler = (item) => {
    setSelection([item.selection]);
    setStartDate(moment(item.selection.startDate).format('DD MMM YYYY'));
    setEndDate(moment(item.selection.endDate).format('DD MMM YYYY'));

    onChange &&
      onChange({
        startDate: moment(startDate).format('YYYY-DD-MM'),
        endDate: moment(endDate).format('YYYY-DD-MM'),
      });
  };

  return (
    <div className={'date-range-wrapper'}>
      <div className={`input-wrapper ${isOpened ? 'expanded' : ''}`}>
        <CalendarIcon />
        <div
          className={'input-date-view'}
          onClick={() => setIsOpened(!isOpened)}
        >
          <span>{`${startDate} - ${endDate}`}</span>
        </div>
        <ExpandCollapseIcon />
      </div>
      <div className={'calendar-wrapper'}>
        {isOpened ? (
          <DateRange
            dateInput={true}
            showDateDisplay={false}
            editableDateInputs={true}
            onChange={onChangeHandler}
            moveRangeOnFirstSelection={false}
            disabledDay={(date) => date > Date.now()}
            rangeColors={['#75F3FB', '#000']}
            color={'black'}
            ranges={selection}
          />
        ) : null}

        {isOpened ? (
          <div className={'buttons'}>
            <Button className="transparent" onClick={() => setIsOpened(false)}>
              Cancel
            </Button>
            <Button className="blue" onClick={() => setIsOpened(false)}>
              Done
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
