import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../../../components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';
import {
  ProductItems,
  ProductsType,
  ProductsTypeBack,
} from 'src/constants/products';
import { useLoader } from 'src/provider/LoaderProvider';
import {
  useCreateProjectPlansMutation,
  useGetAllProjectsQuery,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { clsx } from 'clsx';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useSubscriptionPlans } from 'src/hooks/useSubscriptionPlans';

export const CurrentSubscriptionPlanPopupForm = ({ closePopup }) => {
  const [paymentPrice, setPaymentPrice] = useState('');
  const notification = useNotification();
  const loader = useLoader();

  const [projects, setProjects] = useState([]);
  const [plans, setPlans] = useState([]);

  const { Plans, plansAllIsFetching } = useSubscriptionPlans();

  const { data: projectsData, isFetching: projectsDataIsFetching } =
    useGetAllProjectsQuery();

  const [
    createProjectPlan,
    {
      isLoading: createProjectPlanIsLoading,
      isError: createProjectPlanIsError,
      error: createProjectPlanError,
      isSuccess: createProjectPlanIsSuccess,
    },
  ] = useCreateProjectPlansMutation();

  useEffect(() => {
    if (createProjectPlanIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [createProjectPlanIsLoading]);

  useEffect(() => {
    if (createProjectPlanIsError) {
      notification.error(getErrorMessage(createProjectPlanError));
    }
  }, [createProjectPlanIsError]);

  useEffect(() => {
    if (createProjectPlanIsSuccess) {
      notification.success('Subscription plan was successfully created!');
      closePopup();
    }
  }, [createProjectPlanIsSuccess]);

  const { handleSubmit, control, trigger, watch, setValue } = useForm({
    defaultValues: {
      product: null,
      project: null,
      plan: null,
    },
    resolver: yupResolver(
      object({
        product: object({
          label: stringScheme({ required: false }),
          target_service: stringScheme({ required: false }),
        })
          .required()
          .label('Product'),
        project: object({
          label: stringScheme({ required: false }),
          id: stringScheme({ required: false }),
        })
          .required()
          .label('Project'),
        plan: object({
          label: stringScheme({ required: false }),
          id: stringScheme({ required: false }),
        })
          .required()
          .label('Plan'),
      }),
    ),
  });

  const productWatch = watch('product');

  const submit = (formValues) => {
    createProjectPlan({
      project_id: Number(formValues.project.id),
      plan_id: Number(formValues.plan.id),
      target_service: formValues.product.target_service,
    });
  };

  const Projects = useMemo(
    () =>
      projectsData?.map((item) => {
        return {
          label: item?.name,
          id: item?.id,
          icon: (
            <div
              className={clsx(
                'h-2.5 w-2.5 rounded',
                item?.project_type === ProductsType.agent && 'bg-malibu',
                item?.project_type === ProductsType.bi &&
                  'bg-chartreuse_yellow',
                item?.project_type === ProductsType.customer && 'bg-purple',
              )}
            />
          ),
          disabled: false,
          project_type: item?.project_type,
        };
      }),
    [projectsData, projectsDataIsFetching],
  );

  useEffect(() => {
    if (productWatch && Boolean(Projects?.length)) {
      const productProjectSort =
        Projects?.length &&
        Projects.filter(
          (item) =>
            ProductsTypeBack[item?.project_type] ===
            productWatch?.target_service,
        );
      setValue('project', {
        label: productProjectSort[0]?.label,
        id: productProjectSort[0]?.id,
      });
      setProjects(productProjectSort);
    }
  }, [productWatch, Projects]);

  useEffect(() => {
    if (productWatch && Boolean(Plans?.length)) {
      const productPlanSort =
        Plans?.length &&
        Plans.filter(
          (item) => item?.target_service === productWatch?.target_service,
        );
      setValue('plan', {
        label: productPlanSort[0]?.label,
        id: productPlanSort[0]?.id,
      });
      setPaymentPrice(productPlanSort[0]?.price);
      setPlans(productPlanSort);
    }
  }, [productWatch, Plans]);

  useEffect(() => {
    if (ProductItems) {
      setValue('product', {
        label: ProductItems[0].label,
        target_service: ProductItems[0].target_service,
      });
    }
  }, [ProductItems]);

  return (
    <form id="add-subscription-plan-form" onSubmit={handleSubmit(submit)}>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="product"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'select'}
              selectOptions={ProductItems.map((el) => {
                return { ...el, disabled: false };
              })}
              label={'Product'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={(e) =>
                onChange({ label: e?.label, target_service: e?.target_service })
              }
              onBlur={() => trigger('product')}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <LoaderWrapper
          isLoading={projectsDataIsFetching || !Boolean(projects?.length)}
          loader={
            <div className="flex flex-col">
              <label className="pt-1">Project</label>
              <Skeleton className="w-full h-[52px]" />
            </div>
          }
        >
          <Controller
            control={control}
            name="project"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                dropdownMenuClassNames="max-h-64"
                type={'select'}
                selectOptions={projects}
                label={'Project'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={(e) => onChange({ label: e?.label, id: e?.id })}
                onBlur={() => trigger('project')}
              />
            )}
          />
        </LoaderWrapper>
      </div>
      <div className={'input-container'}>
        <LoaderWrapper
          isLoading={plansAllIsFetching}
          loader={
            <div className="flex flex-col">
              <label className="pt-1">Plan</label>
              <Skeleton className="w-full h-[52px]" />
            </div>
          }
        >
          <Controller
            control={control}
            name="plan"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'select'}
                selectOptions={plans}
                label={'Plan'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={(e) => {
                  onChange({ label: e?.label, id: e?.id });
                  setPaymentPrice(e.price);
                }}
                onBlur={() => trigger('plan')}
              />
            )}
          />
        </LoaderWrapper>
      </div>
      <div className={'input-container'}>
        <Input
          readonly
          type={'text'}
          label={'Payment'}
          placeholder={'499 $'}
          value={paymentPrice}
          isValid
        />
      </div>
    </form>
  );
};
