import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/provider/AuthProvider';
import './LogOutButton.css';
import { clsx } from 'clsx';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import Button from 'src/components/Button/Button';
import useBroadcast from 'src/hooks/useBroadcast';
import {
  BROADCAST_CHANNELS,
  BROADCAST_MESSAGES,
} from 'src/constants/broadcast';
import { PATH_AUTH_SPA } from 'src/constants/spa-routes';

const LogOutButton = ({ className }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const isLgDown = useMediaQuery('(max-width: 992px)');
  const paddings = clsx(
    isLgDown && 'px-2 py-[15px]',
    !isLgDown && 'px-[9px] py-[17px]',
  );

  const { channelInstance } = useBroadcast({
    channelName: BROADCAST_CHANNELS.AUTH,
  });

  const logout = () => {
    auth.signout();
    navigate(PATH_AUTH_SPA.ROOT);
    if (channelInstance) {
      channelInstance.postMessage(BROADCAST_MESSAGES.LOGOUT);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="logout-container flex items-center">
          <div className={clsx('logout-button w-full', className, paddings)}>
            <p>Log Out</p>
            <svg
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.09934 1.88889H9.65489V0H2.09934C1.59837 0 1.11793 0.199007 0.763692 0.553243C0.409457 0.907478 0.210449 1.38792 0.210449 1.88889V15.1111C0.210449 15.6121 0.409457 16.0925 0.763692 16.4468C1.11793 16.801 1.59837 17 2.09934 17H9.65489V15.1111H2.09934V1.88889Z"
                fill="#FFFFFF"
              />
              <path
                d="M12.4384 3.77783V7.55561H5.77759V9.4445H12.4384V13.2223L17.2103 8.50005L12.4384 3.77783Z"
                fill="#FFFFFF"
              />
            </svg>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Log out</DialogTitle>
          <DialogDescription>
            Are you sure you want to log out?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="transparent black !w-fit">Cancel</Button>
          </DialogClose>
          <DialogClose asChild autoFocus onClick={logout}>
            <Button className="transparent red !w-fit">Log Out</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LogOutButton;
