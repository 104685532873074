import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_4390_2834)">
        <path
          d="M13.364 10.636L7 4.27208L0.636039 10.636"
          stroke="#75F3FB"
          strokeWidth="2.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_4390_2834">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const MenuItem = ({
  text,
  icon,
  navigateTo,
  disabled = false,
  isActive = false,
  children,
  onExpand,
  menuItemClassName = '',
  onClose = () => {},
  isPermissionAccess,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname === navigateTo);
  const [expanded, setExpanded] = useState(true);
  const isLgDown = useMediaQuery('(max-width: 992px)');
  let className = clsx(
    'menu-item',
    isLgDown && 'px-2 py-[15px]',
    !isLgDown && 'px-[9px] py-[17px]',
  );

  className = clsx(selected && 'selected', className);
  className = clsx(children && 'expandable', className);
  className = clsx(disabled && 'disabled', className);

  useEffect(() => {
    if (isActive) {
      setSelected(true);
    } else {
      setSelected(location.pathname.includes(navigateTo));
    }
  }, [location.pathname, navigateTo]);

  const handleClick = () => {
    !children && !disabled && navigateTo && navigate(navigateTo);
    if (!disabled) {
      onClose();
    }
    if (children) {
      setExpanded(!expanded);
      onExpand && onExpand(!expanded);
    }
  };

  return children ? (
    <div
      className={clsx(
        expanded && 'menu-item-container expanded',
        !expanded && 'menu-item-container',
      )}
    >
      <div
        className={clsx(expanded && expanded, className, menuItemClassName)}
        onClick={handleClick}
      >
        <div className={'menu-item-icon'}>{icon}</div>
        <div className={'menu-item-text'}>
          <p>{text}</p>
        </div>
        {children && <Icon />}
      </div>
      <div className="children-items">{children}</div>
    </div>
  ) : (
    <div className="relative">
      {isPermissionAccess && (
        <WarningPopover
          triggerClassName={
            'absolute left-36 items-center flex justify-center top-2'
          }
          textColor="white"
        />
      )}
      <div
        className={clsx(
          className,
          menuItemClassName,
          isPermissionAccess && 'pointer-events-none opacity-40',
        )}
        onClick={handleClick}
      >
        <div className="flex md:gap-[14px] gap-[8px] items-center menu-item-wrapper">
          <div className={'menu-item-icon'}>{icon}</div>
          <div className={'menu-item-text'}>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
