import React from 'react';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import { Accordion } from 'src/components/Accorion/Accrdion';
import { ProjectEditNameContent } from 'src/components/ProjectSettings/Popups/ProjectEditNameContent';
import { ProjectDeleteContent } from 'src/components/ProjectSettings/Popups/ProjectDeleteContent';
import { DeleteIcon } from 'src/assets/img/delete-icon';
import { ProjectActivateContent } from 'src/components/ProjectSettings/Popups/ProjectActivateContent';
import { useParams } from 'react-router-dom';
import { useGetAllProjectsQuery } from 'src/services';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { ROLES } from 'src/constants/roles';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';

export const SettingsScreen = () => {
  const { id } = useParams();
  const { data: projects } = useGetAllProjectsQuery();

  const { data: userInfo } = useGetUsersMeQuery();

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  const currentProject = projects?.find((project) => project.id === +id);
  return (
    <React.Fragment>
      <PageContentWrapper
        projectBreadcrumbsContent={'Settings'}
        className="project-page-container"
        isMobileMarginTop={false}
      >
        <Accordion className={'!bg-white'} title={'Edit project name'}>
          <ProjectEditNameContent onlyForm />
        </Accordion>
        {/*<Accordion disabled className={'!bg-white'} title={'Hibernate project'}>*/}
        {/*  <ProjectEditNameContent onlyForm />*/}
        {/*</Accordion>*/}
        <div className="relative">
          {!isCosupportManager && (
            <WarningPopover
              triggerClassName="absolute left-36 top-2 z-10"
              fill="black"
              text="Please contact your CoSupport Account Manager to activate the project."
              contentMaxWidth="max-w-56"
            />
          )}
          <Accordion
            className={'!bg-white'}
            disabled={!isCosupportManager}
            title={
              currentProject?.is_active
                ? 'Deactivate project'
                : 'Activate project'
            }
          >
            <ProjectActivateContent
              onlyForm
              isActive={currentProject?.is_active}
            />
          </Accordion>
        </div>
        <Accordion
          className={'!bg-white'}
          title={
            <div className="flex items-center gap-3">
              Delete project <DeleteIcon />
            </div>
          }
        >
          <ProjectDeleteContent onlyForm />
        </Accordion>
      </PageContentWrapper>
    </React.Fragment>
  );
};
