import React from 'react';

export const FileTxtIcon = ({ width = 14, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM8.75 2.1726V5.4H11.8877L8.75 2.1726Z"
      fill="#3145B0"
    />
    <rect x="3.50024" y="8.64014" width="7" height="1.44" fill="#3145B0" />
    <rect x="3.50024" y="10.8002" width="7" height="1.44" fill="#3145B0" />
    <rect x="3.50024" y="12.9602" width="7" height="1.44" fill="#3145B0" />
  </svg>
);

export const FilePdfIcon = ({ width = 14, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM7.8645 8.8632C8.07395 10.234 8.77089 11.4758 9.82012 12.348C10.5962 12.9933 9.88662 14.2569 8.95562 13.8888C7.69692 13.3903 6.30308 13.3903 5.04438 13.8888C4.1125 14.2578 3.40287 12.9933 4.179 12.348C5.22824 11.4758 5.92517 10.234 6.13462 8.8632C6.29037 7.8489 7.70962 7.8498 7.8645 8.8632ZM6.99825 10.9368L6.39625 12.0132H7.60375L6.99825 10.9368ZM8.75 2.1726V5.4H11.8878L8.75 2.1726Z"
      fill="#C80000"
    />
  </svg>
);

export const FileMdIcon = ({ width = 14, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM8.75 2.1726V5.4H11.8877L8.75 2.1726Z"
      fill="#6A31B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89403 8.49561H3.00708V12.6716H4.01608V10.6161L4.75547 11.9662H5.04249C5.14189 11.9662 5.23443 11.9375 5.31429 11.8773L5.31549 11.8763C5.39505 11.815 5.46254 11.7232 5.52142 11.6142L6.05269 10.6253V12.6716H7.0617V8.49561H6.17387L5.04653 10.6491L3.89403 8.49561ZM9.39668 8.49561H7.45307V12.0055C7.45307 12.195 7.51372 12.3601 7.64176 12.4869C7.76892 12.6129 7.93161 12.6716 8.11687 12.6716H9.41828C9.90875 12.6716 10.3043 12.5433 10.5803 12.2636L10.5811 12.2629C10.8569 11.9792 10.9839 11.5741 10.9839 11.0724V9.98375C10.9839 9.69386 10.9171 9.43218 10.7799 9.20345C10.6464 8.97511 10.4567 8.79962 10.2149 8.67723C9.97614 8.55454 9.70209 8.49561 9.39668 8.49561ZM8.47827 11.706V9.46123H9.37508C9.56209 9.46123 9.70001 9.51638 9.80266 9.61626L9.80415 9.61766C9.90448 9.71249 9.95868 9.84034 9.95868 10.0171V11.0391C9.95868 11.2861 9.90346 11.4478 9.816 11.5486C9.731 11.6467 9.59953 11.706 9.39668 11.706H8.47827Z"
      fill="#6A31B3"
    />
  </svg>
);

export const FileSettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="8.5"
      stroke="#B8C1C6"
      strokeOpacity="0.4"
    />
    <circle cx="9" cy="5" r="1" fill="#05050D" fillOpacity="0.5" />
    <circle cx="9" cy="9" r="1" fill="#05050D" fillOpacity="0.5" />
    <circle cx="9" cy="13" r="1" fill="#05050D" fillOpacity="0.5" />
  </svg>
);

export const CreateDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M10.6791 13.1885H1.83686V4.18415H6.99482V2.71045H1.83686C1.02633 2.71045 0.363159 3.37362 0.363159 4.18415V13.0264C0.363159 13.8369 1.02633 14.5001 1.83686 14.5001H10.6791C11.4896 14.5001 12.1528 13.8369 12.1528 13.0264V7.86841H10.6791V13.1885Z"
      fill="#05050D"
    />
    <path
      d="M12.1529 0.5H10.6792V2.71055H8.46863C8.476 2.71792 8.46863 4.18426 8.46863 4.18426H10.6792V6.38744C10.6866 6.39481 12.1529 6.38744 12.1529 6.38744V4.18426H14.3634V2.71055H12.1529V0.5ZM3.31067 5.65796H9.20548V7.13166H3.31067V5.65796ZM3.31067 7.86851V9.34222H9.20548V7.86851H6.99493H3.31067ZM3.31067 10.0791H9.20548V11.5528H3.31067V10.0791Z"
      fill="#05050D"
    />
  </svg>
);

export const CreateDocumentMobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
  >
    <path
      d="M2.21176 15.5H9.78824C11.2652 15.5 12 14.7654 12 13.3101V6.95761H6.83466C5.92147 6.95761 5.49348 6.53075 5.49348 5.63522V0.5H2.21176C0.742124 0.5 0 1.24149 0 2.69701V13.3101C0 14.7722 0.742124 15.5 2.21176 15.5ZM6.85627 5.9991H11.9215C11.8715 5.71224 11.6646 5.43224 11.3294 5.08955L7.3911 1.16478C7.06296 0.828955 6.76343 0.62597 6.4636 0.576716V5.62149C6.4636 5.87313 6.59936 5.9991 6.85627 5.9991Z"
      fill="black"
    />
  </svg>
);

export const MoveContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
  >
    <path
      d="M7.4 12C7.015 12 6.68553 11.8655 6.4116 11.5964C6.13767 11.3274 6.00047 11.0036 6 10.625V2.375C6 1.99687 6.1372 1.67329 6.4116 1.40425C6.686 1.13521 7.01547 1.00046 7.4 1H11.6L13 2.375H18.6C18.985 2.375 19.3147 2.50975 19.5891 2.77925C19.8635 3.04875 20.0005 3.37233 20 3.75V10.625C20 11.0031 19.863 11.3269 19.5891 11.5964C19.3152 11.8659 18.9855 12.0005 18.6 12H7.4ZM7.4 10.625H18.6V3.75H12.4225L11.0225 2.375H7.4V10.625Z"
      fill="#05050D"
      stroke="#05050D"
      strokeWidth="0.2"
    />
    <rect x="4" y="5" width="4" height="5" fill="white" />
    <path
      d="M2.82837 7.41431L7.82837 7.41431"
      stroke="#05050D"
      strokeWidth="1.5"
    />
    <path
      d="M4.41422 9.82852L2 7.41431L4.41422 5.00009"
      stroke="#05050D"
      strokeWidth="1.5"
    />
  </svg>
);

export const DeleteContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <path
      d="M4.05 11L6 8.97778L7.95 11L9 9.91111L7.05 7.88889L9 5.86667L7.95 4.77778L6 6.8L4.05 4.77778L3 5.86667L4.95 7.88889L3 9.91111L4.05 11ZM2.25 14.5C1.8375 14.5 1.4845 14.3478 1.191 14.0434C0.8975 13.7391 0.7505 13.3727 0.75 12.9444V2.83333H0V1.27778H3.75V0.5H8.25V1.27778H12V2.83333H11.25V12.9444C11.25 13.3722 11.1033 13.7386 10.8097 14.0434C10.5162 14.3483 10.163 14.5005 9.75 14.5H2.25Z"
      fill="#05050D"
    />
  </svg>
);

export const MoveFilesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
  >
    <path
      d="M7.4 12C7.015 12 6.68553 11.8655 6.4116 11.5964C6.13767 11.3274 6.00047 11.0036 6 10.625V2.375C6 1.99687 6.1372 1.67329 6.4116 1.40425C6.686 1.13521 7.01547 1.00046 7.4 1H11.6L13 2.375H18.6C18.985 2.375 19.3147 2.50975 19.5891 2.77925C19.8635 3.04875 20.0005 3.37233 20 3.75V10.625C20 11.0031 19.863 11.3269 19.5891 11.5964C19.3152 11.8659 18.9855 12.0005 18.6 12H7.4ZM7.4 10.625H18.6V3.75H12.4225L11.0225 2.375H7.4V10.625Z"
      fill="#A498FF"
      stroke="#A498FF"
      strokeWidth="0.2"
    />
    <rect x="4" y="5" width="4" height="5" fill="#05050D" />
    <path
      d="M2.82837 7.41406L7.82837 7.41406"
      stroke="#A498FF"
      strokeWidth="1.5"
    />
    <path
      d="M4.41422 9.82828L2 7.41406L4.41422 4.99985"
      stroke="#A498FF"
      strokeWidth="1.5"
    />
  </svg>
);

export const DeleteFilesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <path
      d="M4.05 11L6 8.97778L7.95 11L9 9.91111L7.05 7.88889L9 5.86667L7.95 4.77778L6 6.8L4.05 4.77778L3 5.86667L4.95 7.88889L3 9.91111L4.05 11ZM2.25 14.5C1.8375 14.5 1.4845 14.3478 1.191 14.0434C0.8975 13.7391 0.7505 13.3727 0.75 12.9444V2.83333H0V1.27778H3.75V0.5H8.25V1.27778H12V2.83333H11.25V12.9444C11.25 13.3722 11.1033 13.7386 10.8097 14.0434C10.5162 14.3483 10.163 14.5005 9.75 14.5H2.25Z"
      fill="white"
    />
  </svg>
);

export const FilesListViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M3 7.875C2.3775 7.875 1.875 8.3775 1.875 9C1.875 9.6225 2.3775 10.125 3 10.125C3.6225 10.125 4.125 9.6225 4.125 9C4.125 8.3775 3.6225 7.875 3 7.875ZM3 3.375C2.3775 3.375 1.875 3.8775 1.875 4.5C1.875 5.1225 2.3775 5.625 3 5.625C3.6225 5.625 4.125 5.1225 4.125 4.5C4.125 3.8775 3.6225 3.375 3 3.375ZM3 12.375C2.3775 12.375 1.875 12.885 1.875 13.5C1.875 14.115 2.385 14.625 3 14.625C3.615 14.625 4.125 14.115 4.125 13.5C4.125 12.885 3.6225 12.375 3 12.375ZM5.25 14.25H15.75V12.75H5.25V14.25ZM5.25 9.75H15.75V8.25H5.25V9.75ZM5.25 3.75V5.25H15.75V3.75H5.25Z"
      fill="black"
    />
  </svg>
);

export const FilesGridViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M2.25 2.25V8.25H8.25V2.25H2.25ZM6.75 6.75H3.75V3.75H6.75V6.75ZM2.25 9.75V15.75H8.25V9.75H2.25ZM6.75 14.25H3.75V11.25H6.75V14.25ZM9.75 2.25V8.25H15.75V2.25H9.75ZM14.25 6.75H11.25V3.75H14.25V6.75ZM9.75 9.75V15.75H15.75V9.75H9.75ZM14.25 14.25H11.25V11.25H14.25V14.25Z"
      fill="black"
    />
  </svg>
);

export const FilesSortIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14.7563 0H0.225014C0.163671 0.0052583 0.106387 0.0360976 0.064655 0.0863301C0.0229234 0.136563 -0.000167794 0.202472 1.44833e-05 0.270833V1.16667C-0.000548793 1.25849 0.0153232 1.34952 0.0467073 1.43446C0.0780915 1.5194 0.124362 1.59656 0.182827 1.66146L5.80782 7.91146V13.1198L9.20625 15V7.90104L14.8313 1.65104C14.9389 1.52207 14.9991 1.35296 15 1.17708V0.270833C15 0.199004 14.9743 0.130116 14.9286 0.0793253C14.8829 0.0285342 14.8209 0 14.7563 0Z"
      fill="#05050D"
    />
  </svg>
);

export const AddContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
  >
    <path d="M3.5 7L3.5 -2.20141e-07" stroke="#05050D" />
    <line x1="-7.28524e-09" y1="3.63635" x2="7" y2="3.63635" stroke="#05050D" />
  </svg>
);

export const AddFolderMobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
  >
    <path
      d="M1.5 12C1.0875 12 0.7345 11.8532 0.441 11.5597C0.1475 11.2662 0.0005 10.913 0 10.5V1.5C0 1.0875 0.147 0.7345 0.441 0.441C0.735 0.1475 1.088 0.0005 1.5 0H6L7.5 1.5H13.5C13.9125 1.5 14.2657 1.647 14.5597 1.941C14.8537 2.235 15.0005 2.588 15 3V10.5C15 10.9125 14.8532 11.2657 14.5597 11.5597C14.2662 11.8537 13.913 12.0005 13.5 12H1.5Z"
      fill="#05050D"
    />
  </svg>
);

export const UploadFileMobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
  >
    <g clipPath="url(#clip0_14554_30131)">
      <path
        d="M1.41578 14.7095C-0.497103 12.8255 -0.461451 9.78731 1.46116 7.91013L8.3283 1.20509C9.77912 -0.211531 12.1374 -0.211656 13.5884 1.20509C15.0252 2.60803 15.027 4.87163 13.5884 6.27622L7.60224 12.1153C6.62484 13.0696 5.02794 13.0563 4.06711 12.0842C3.14144 11.1476 3.17114 9.66313 4.11465 8.74188L8.82052 4.15328C9.02291 3.95597 9.35454 3.95259 9.56125 4.14575L10.3097 4.84509C10.5164 5.03828 10.5199 5.35484 10.3176 5.55216L5.61219 10.1403C5.45072 10.298 5.44077 10.5599 5.59097 10.7119C5.7341 10.8568 5.95911 10.8592 6.10457 10.7171L12.0907 4.878C12.7328 4.25106 12.7328 3.23034 12.0903 2.60306C11.4621 1.98972 10.4548 1.98941 9.82636 2.60306L2.95919 9.30806C1.82111 10.4193 1.80357 12.2181 2.9202 13.3178C4.03362 14.4145 5.8334 14.4159 6.94888 13.3268L12.5818 7.82681C12.784 7.62934 13.1157 7.62578 13.3225 7.81881L14.0715 8.51769C14.2784 8.71072 14.2821 9.02728 14.0799 9.22475L8.44694 14.7247C6.49654 16.629 3.34167 16.6064 1.41578 14.7095Z"
        fill="#05050D"
      />
    </g>
    <defs>
      <clipPath id="clip0_14554_30131">
        <rect
          width="14.6667"
          height="16"
          fill="white"
          transform="translate(0 0.142578)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const UploadFileArrowMobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path d="M7.00001 14.5L7.00001 0.499993" stroke="#3B3486" strokeWidth="2" />
    <line
      x1="1.60275e-07"
      y1="7.07275"
      x2="14"
      y2="7.07275"
      stroke="#3B3486"
      strokeWidth="2"
    />
  </svg>
);
