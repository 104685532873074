import './FilePathContainer.css';
import React from 'react';
import { FolderIcon } from 'src/assets/icons/FolderIcon';
import { getFileExtension, getParentsFolders } from 'src/utils/fileUtils';
import { FilePathArrowIcon } from 'src/assets/icons/FilePathArrowIcon';
import {
  FileMdIcon,
  FilePdfIcon,
  FileTxtIcon,
} from 'src/assets/icons/FileIcons';

export const FilePathContainer = ({ file, updateActiveBranch }) => {
  const fullPathItems = file ? getParentsFolders(file) : [];

  const onClick = (item) => {
    item.type === 'folder' && updateActiveBranch(item);
  };

  return (
    <div className="file-path-container flex flex-row justify-start gap-[5px] mt-[20px]">
      {fullPathItems.map((item, index) => {
        const ext = getFileExtension(item.path);

        return (
          <div
            className="flex flex-row items-center justify-center gap-[3px] hover:cursor-pointer hover:underline decoration-semi-transparent-grey"
            key={`${item.path}-${index}`}
            onClick={() => onClick(item)}
          >
            <div className="max-h-[14px]">
              {item.type === 'folder' && <FolderIcon />}
              {item.type === 'file' && !ext && (
                <FileTxtIcon width={12} height={14} />
              )}
              {item.type === 'file' && ext === 'pdf' && (
                <FilePdfIcon width={12} height={14} />
              )}
              {item.type === 'file' && ext === 'md' && (
                <FileMdIcon width={12} height={14} />
              )}
              {item.type === 'file' && ext === 'txt' && (
                <FileTxtIcon width={12} height={14} />
              )}
            </div>
            <span className="font-jamjuree text-[10px] text-semi-transparent-grey select-none">
              {item.name || 'Knowledge Base '}{' '}
            </span>
            {index !== fullPathItems.length - 1 && <FilePathArrowIcon />}
          </div>
        );
      })}
    </div>
  );
};
