import React, { useState } from 'react';
import Button from '../../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Input from 'src/components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import {
  AddContentIcon,
  AddFolderMobileIcon,
  CreateDocumentMobileIcon,
  UploadFileArrowMobileIcon,
  UploadFileMobileIcon,
} from 'src/assets/icons/FileIcons';
import { Accordion } from 'src/components/Accorion/Accrdion';
import { getFileExtension } from 'src/utils/fileUtils';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';

export const AddContentPopup = ({
  addFolder,
  onUploadFile,
  createDocument,
  activeBranch,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit: handleSubmitAddFolder,
    control: controlAddFolder,
    setError: setErrorAddFolder,
  } = useForm({
    defaultValues: {
      folder_name: '',
    },
    resolver: yupResolver(
      object({
        folder_name: stringScheme({ required: true, minLength: 3 }).label(
          'Folder name',
        ),
      }),
    ),
  });

  const { handleSubmit: handleSubmitAddDocument, control: controlAddDocument } =
    useForm({
      defaultValues: {
        name: '',
        text: '',
      },
      resolver: yupResolver(
        object({
          name: stringScheme({ required: true, minLength: 3 }).label(
            'Document Name',
          ),
          text: stringScheme({ required: true, minLength: 3 }).label('Text'),
        }),
      ),
    });

  const submitAddFolder = ({ folder_name }) => {
    const folders = activeBranch.children.filter(
      ({ type }) => type === 'folder',
    );
    const exist = folders.some((folder) => folder.name === folder_name);

    if (exist) {
      return setErrorAddFolder('folder_name', {
        type: 'manual',
        message: `folder already exists!`,
      });
    }

    addFolder(folder_name);
    setOpen(false);
  };

  const submitAddDocument = (data) => {
    const ext = getFileExtension(data.name);

    if (ext) {
      const allowedExtension = KNOWLEDGE_BASE_FILE_EXTENSIONS.includes(
        `.${ext}`,
      );

      data.name = allowedExtension
        ? data.name
        : data.name.replace(`.${ext}`, '.txt');
    } else {
      data.name = `${data.name}.txt`;
    }

    createDocument(data);
    setOpen(false);
  };

  const handleUploadFile = () => {
    onUploadFile && onUploadFile();
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer',
          )}
        >
          <Button className="transparent black gap-[10px]">
            Add content
            <AddContentIcon />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add content</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content gap-[6px] flex flex-col">
              <Accordion
                className={'purple'}
                title={
                  <>
                    <AddFolderMobileIcon /> Add folder
                  </>
                }
              >
                <form
                  id="add-new-folder"
                  onSubmit={handleSubmitAddFolder(submitAddFolder)}
                >
                  <div className={'input-container'}>
                    <Controller
                      control={controlAddFolder}
                      name="folder_name"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          type={'text'}
                          label={'Folder Name'}
                          placeholder={'New Folder Name'}
                          invalidMessage={error?.message}
                          value={value}
                          isValid={!error?.message}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={
                      'flex flex-row gap-[10px] [&>button]:py-[8px] [&>button]:px-[33px] mt-[14px]'
                    }
                  >
                    <DialogClose asChild>
                      <Button
                        style={{ width: 'auto' }}
                        className="transparent black"
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <Button
                      style={{ width: 'auto' }}
                      type="submit"
                      form="add-new-folder"
                      className="purple"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </Accordion>
              <Accordion
                className={'purple'}
                title={
                  <>
                    {' '}
                    <CreateDocumentMobileIcon /> Create document{' '}
                  </>
                }
              >
                <form
                  id="create-new-document"
                  onSubmit={handleSubmitAddDocument(submitAddDocument)}
                >
                  <div className={'input-container'}>
                    <Controller
                      control={controlAddDocument}
                      name="name"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          type={'text'}
                          label={'Document Name'}
                          placeholder={'New Document Name'}
                          invalidMessage={error?.message}
                          value={value}
                          isValid={!error?.message}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <div className={'input-container'}>
                    <Controller
                      control={controlAddDocument}
                      name="text"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          type={'textarea'}
                          label={'Text'}
                          placeholder={'some text...'}
                          invalidMessage={error?.message}
                          value={value}
                          isValid={!error?.message}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </form>
                <div
                  className={
                    'flex flex-row gap-[10px] [&>button]:py-[8px] [&>button]:px-[33px] mt-[14px]'
                  }
                >
                  <DialogClose asChild>
                    <Button
                      style={{ width: 'auto' }}
                      className="transparent black"
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    style={{ width: 'auto' }}
                    type="submit"
                    form="create-new-document"
                    className="purple"
                  >
                    Save
                  </Button>
                </div>
              </Accordion>
              <Accordion
                className={'purple'}
                arrowIcon={<UploadFileArrowMobileIcon />}
                onClick={handleUploadFile}
                title={
                  <>
                    <UploadFileMobileIcon /> Upload file{' '}
                  </>
                }
              ></Accordion>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
