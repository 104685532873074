import React from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useAuth } from '../../provider/AuthProvider';
import { useNotification } from '../../hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  emailScheme,
  passwordConfirmScheme,
  passwordScheme,
  phoneNumberScheme,
  stringScheme,
} from '../../utils/validation-schemes';
import { useLoader } from '../../provider/LoaderProvider';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';

export const RegisterForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const notification = useNotification();
  const loader = useLoader();

  const submit = async (data) => {
    loader.show();
    const response = await auth.signup({
      email: data.email,
      password: data.password,
      password2: data.passwordConfirm,
      first_name: data.firstName,
      last_name: data.lastName,
      company_name: data.companyName,
      company_email: data.companyEmail,
      account_phone_number: data.phone,
    });

    if (response instanceof Error) {
      loader.hide();
      return response.message && notification.error(response.message);
    }

    const loginResponse = await auth.signin({
      email: response.email,
      password: response.password,
    });

    if (loginResponse instanceof Error) {
      loader.hide();
      return loginResponse.message && notification.error(loginResponse.message);
    }
    loader.hide();
    navigate(PATH_DASHBOARD.ACCOUNT);
  };

  const { handleSubmit, trigger, control, clearErrors } = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      firstName: '',
      lastName: '',
      companyName: '',
      companyEmail: '',
      phone: '',
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        password: passwordScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
        passwordConfirm: passwordConfirmScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password Confirm'),
        firstName: stringScheme({ required: true }).label('First name'),
        lastName: stringScheme({ required: true }).label('Last name'),
        companyName: stringScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Company name'),
        companyEmail: emailScheme({ required: true }).label('Company email'),
        phone: phoneNumberScheme({ required: true }),
      }),
    ),
  });

  return (
    <React.Fragment>
      <form id="registration-form" onSubmit={handleSubmit(submit)}>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                autoFocus
                type={'text'}
                label={'First Name'}
                placeholder={'Josh'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('firstName')}
                onFocus={() => clearErrors('firstName')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Last Name'}
                placeholder={'Brawn'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('lastName')}
                onFocus={() => clearErrors('lastName')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'email@email.com'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('email')}
                onFocus={() => clearErrors('email')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="companyName"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Company Name'}
                placeholder={'Company'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('companyName')}
                onFocus={() => clearErrors('companyName')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="companyEmail"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'email'}
                label={'Company Email'}
                placeholder={'email@company.com'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('companyEmail')}
                onFocus={() => clearErrors('companyEmail')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'phone'}
                label={'Account Phone Number'}
                placeholder={'+1 (555) 455 88 99'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('phone')}
                onFocus={() => clearErrors('phone')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'password'}
                label={'Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onFocus={() => clearErrors('password')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="passwordConfirm"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'password'}
                label={'Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onFocus={() => clearErrors('passwordConfirm')}
              />
            )}
          />
        </div>
      </form>
      <div className="flex flex-col mt-6 md:gap-4 gap-3">
        <Button form="registration-form" type="sumbit" className="blue">
          Create Account
        </Button>
        <Button className="google" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};
