import { baseApi } from './base.api';
import {
  ACCOUNTS_URL,
  PATH_BILLING_API,
  PATH_USERS_API,
} from '../constants/api-routes';
import { projectsService } from 'src/services/projectsService';

export const usersService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsersMe: build.query({
      query: () => ({
        url: `${PATH_USERS_API.ME}`,
      }),
      providesTags: ['UserMe'],
    }),
    updateUsersMe: build.mutation({
      query: (body) => ({
        url: `${PATH_USERS_API.ME}`,
        method: 'PUT',
        body: body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;
          dispatch(
            usersService.util.updateQueryData(
              'getUsersMe',
              undefined,
              (draft) => {
                Object.assign(draft, updatedUser);
              },
            ),
          );
        } catch {}
      },
    }),
    getUsersAll: build.query({
      query: () => ({
        url: `${PATH_USERS_API.ALL}`,
      }),
      providesTags: ['UsersAll'],
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: `${PATH_USERS_API.CREATE}`,
        method: 'POST',
        body: body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: newUser } = await queryFulfilled;
          console.log('newUser', newUser);
          dispatch(
            usersService.util.updateQueryData(
              'getUsersAll',
              undefined,
              (draft) => {
                draft.push(newUser);
              },
            ),
          );
        } catch {}
      },
      invalidatesTags: ['CreateUser'],
    }),
    updateUser: build.mutation({
      query: ({ userId, body }) => ({
        url: `${PATH_USERS_API.ROOT}/${userId}${PATH_USERS_API.UPDATE}`,
        method: 'PUT',
        body: body,
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;
          dispatch(
            usersService.util.updateQueryData(
              'getUsersAll',
              undefined,
              (draft) => {
                const currentUser = draft.find(
                  (user) => user.id === updatedUser.id,
                );
                Object.assign(currentUser, updatedUser);
              },
            ),
          );
        } catch (err) {
          console.error('Error updating user:', err);
        }
      },
      invalidatesTags: ['ProjectById'],
    }),
    deleteUser: build.mutation({
      query: ({ userId }) => ({
        url: `${PATH_USERS_API.ROOT}/${userId}${PATH_USERS_API.DELETE}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'UsersAll', id }];
      },
    }),
  }),
});

export const {
  useGetUsersMeQuery,
  useUpdateUsersMeMutation,
  useGetUsersAllQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} = usersService;
