import './SearchFilesContainer.css';
import React, { useState } from 'react';
import Input from 'src/components/Input/Input';
import { SearchInputIcon } from 'src/assets/icons/SearchInputIcon';
import { ViewTypesTabs } from 'src/components/FileManager/SearchFilesContainer/ViewTypesTabs/ViewTypesTabs';
import { SortFilesDropdown } from 'src/components/FileManager/SearchFilesContainer/SortFilesDropdown/SortFilesDropdown';

export const SearchFilesContainer = ({
  onSearch,
  onSortFilesChange,
  onViewTypeChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const handleFileSearch = (event) => {
    const value = event.currentTarget.value;
    setInputValue(value);
    onSearch && onSearch(value.length >= 1 ? value : '');
  };

  return (
    <div className="search-files-container flex flex-row justify-end gap-[10px]">
      <Input
        type="text"
        isValid={true}
        icon={
          <div className="absolute right-[10px]">
            <SearchInputIcon />
          </div>
        }
        value={inputValue}
        placeholder="Search"
        onChange={handleFileSearch}
      />
      <SortFilesDropdown onSortFilesChange={onSortFilesChange} />
      <ViewTypesTabs onViewTypeChange={onViewTypeChange} />
    </div>
  );
};
