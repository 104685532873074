const STORAGE_KEY = {
  KNOWLEDGE_BASE_PAGE: 'KNOWLEDGE_BASE_PAGE',
  ANALYTICS_PAGE: 'ANALYTICS_PAGE',
};

export const getPageConfig = (key, storage = localStorage) => {
  return JSON.parse(storage.getItem(key) || '{}');
};

export const setPageConfig = (key, config, storage = localStorage) => {
  const storageConfig = getPageConfig(key);

  storage.setItem(
    key,
    JSON.stringify({
      ...storageConfig,
      ...config,
    }),
  );
};

export const getKnowledgeBaseConfig = () => {
  const { KNOWLEDGE_BASE_PAGE } = STORAGE_KEY;

  return getPageConfig(KNOWLEDGE_BASE_PAGE);
};

export const setKnowledgeBaseConfig = (config) => {
  const { KNOWLEDGE_BASE_PAGE } = STORAGE_KEY;

  return setPageConfig(KNOWLEDGE_BASE_PAGE, config);
};

export const getAnalyticsPageConfig = () => {
  const { ANALYTICS_PAGE } = STORAGE_KEY;

  return getPageConfig(ANALYTICS_PAGE, sessionStorage);
};

export const setAnalyticsPageConfig = (config) => {
  const { ANALYTICS_PAGE } = STORAGE_KEY;

  return setPageConfig(ANALYTICS_PAGE, config, sessionStorage);
};
